//react
import React, { useState } from "react";
// import { Link } from "react-router-dom";

//bootstrap
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";

//components
import SignUpForm from "./SignupForm";
import LoginForm from "./LoginForm";

//auth
import Auth from "../utils/auth";

function BasicExample() {
  // set modal display state
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Navbar
        id="mainNavbar"
        expand="md"
        sticky="top"
        variant="dark"
        bg="dark"
        className="border-bottom border-secondary mb-2"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={`/logo/logo.svg`}
              width="30"
              height="30"
              className="d-inline-block align-top me-2"
            />
            <span>Array of Apps</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto column-gap-3">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/games">Games</Nav.Link>
              <Nav.Link href="/tools">Tools</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav>
            <Nav>
              {/* if user is logged in show favorites and user settings and logout */}
              {Auth.loggedIn() ? (
                <>
                  <NavDropdown
                    title={`Signed in as ${Auth.getProfile().data.name}`}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="#action/3.1">
                      Your profile
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Settings
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={Auth.logout}>
                      {/* <Nav.Link onClick={Auth.logout}>Logout</Nav.Link> */}
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Nav.Link onClick={() => setShowModal(true)}>
                  Login/Sign Up
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* set modal data up */}
      <Modal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="signup-modal"
        autoFocus="true"
        scrollable="true"
      >
        {/* tab container to do either signup or login component */}
        <Tab.Container defaultActiveKey="login">
          <Modal.Header closeButton>
            <Modal.Title as="h5" id="signup-modal" className="w-100">
              <Nav fill variant="pills">
                <Nav.Item className="px-1">
                  <Nav.Link eventKey="login">Login</Nav.Link>
                </Nav.Item>
                <Nav.Item className="px-1">
                  <Nav.Link eventKey="signup">Sign Up</Nav.Link>
                </Nav.Item>
              </Nav>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Content>
              <Tab.Pane eventKey="login">
                <LoginForm handleModalClose={() => setShowModal(false)} />
              </Tab.Pane>
              <Tab.Pane eventKey="signup">
                <SignUpForm handleModalClose={() => setShowModal(false)} />
              </Tab.Pane>
            </Tab.Content>
          </Modal.Body>
        </Tab.Container>
      </Modal>
    </>
  );
}

export default BasicExample;
