//react
import React, { useState } from "react";

//bootstrap
import { Container, Row, Col, Form } from "react-bootstrap";

const JSON = () => {
  // STATE
  const [formJSONInputState, setFormJSONInputState] = useState([]);

  const handleChange = (event) => {
    event.target.style.height = "inherit";
    event.target.style.height = `${event.target.scrollHeight + 1}px`;
    const { value } = event.target;
    // console.log(value);

    const jsonCleaning = value.replace(/(\s\s)*/g, "");

    const jsonSplit = jsonCleaning.split("");
    // console.log(jsonSplit);

    let indentLevel = 0;
    const indentFactor = 2;
    let quoteOpen = false;
    let jsonCleaned = [""];
    jsonSplit.forEach((c, index) => {
      c === '"' && (quoteOpen = !quoteOpen);

      if (quoteOpen) {
        jsonCleaned[jsonCleaned.length - 1] += c;
      } else {
        switch (c) {
          case " ":
          case "\n":
            break;
          case ":":
            jsonCleaned[jsonCleaned.length - 1] += c + " ";
            break;
          case "{":
          case "[":
            indentLevel++;
            jsonCleaned[jsonCleaned.length - 1] += c;
            jsonCleaned.push("".padStart(indentLevel * indentFactor));
            break;
          case "}":
          case "]":
            indentLevel--;
            jsonCleaned.push("".padStart(indentLevel * indentFactor));
            jsonCleaned[jsonCleaned.length - 1] += c;
            break;
          case ",":
            jsonCleaned[jsonCleaned.length - 1] += c;
            jsonCleaned.push("".padStart(indentLevel * indentFactor));
            break;
          default:
            jsonCleaned[jsonCleaned.length - 1] += c;
            break;
        }
      }
    });

    // console.log(jsonCleaned);

    setFormJSONInputState(jsonCleaned);
  };

  return (
    <>
      <Container className="text-light pb-2">
        <h3>JSON</h3>
        <p>Visualize your JSON data.</p>
      </Container>
      <Container className="text-light pb-2">
        <Row className="mb-3">
          <Col xs={6}>
            <div className="border rounded border-light p-3 text-wrap text-break h-100">
              <Form.Group className="mb-3">
                <Form.Label>Paste your JSON Below:</Form.Label>
                <Form.Control
                  as="textarea"
                  onChange={handleChange}
                  className="fontCode overflow-hidden bg-secondary text-light"
                />
              </Form.Group>
            </div>
          </Col>
          <Col xs={6}>
            <div className="border rounded border-light p-3 text-wrap text-break h-100">
              <p>Results:</p>
              <div className="fontCode">
                {formJSONInputState.map((newline, i) => {
                  return (
                    <span
                      key={`line-${i}`}
                      id={`line-${i}`}
                      className="linebreak"
                    >
                      {/* {newline === "{" && "{"}
                    {newline === "}" && "&#125"}
                    {newline === "[" && "&#91"}
                    {newline === "]" && "&#93"} */}
                      {newline}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="text-light pb-2">
        <h3>Tree Map</h3>
        <p></p>
      </Container>
    </>
  );
};

export default JSON;
