// import React from "react";
import React, { useEffect, useState } from "react";

//bootstrap
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import {
  Pencil,
  Check2Square,
  ArrowClockwise,
  Trash3,
} from "react-bootstrap-icons";

const FormField = ({
  name,
  value,
  saveFieldValue,
  deleteFieldValue,
  resetValidation,
  editMode,
  editOnNew,
  type = "text",
  parameter = true,
  nameDropdown,
  newParameter = false,
  fieldValidation = false,
}) => {
  const [formInput, setFormInput] = useState({
    editField: newParameter
      ? true
      : nameDropdown === "New API..." && editOnNew
      ? true
      : false,
    editValue: { name: name, value: value },
  });

  useEffect(() => {
    setFormInput({
      editField: newParameter
        ? true
        : nameDropdown === "New API..." && editOnNew
        ? true
        : false,
      editValue: { name: name, value: value },
    });
  }, [nameDropdown, newParameter, editOnNew, name, value]);

  // useEffect(() => {
  //   if (nameDropdown === "New API...") {
  //     setFormInput({
  //       editField: true,
  //       editValue: { name: name, value: value },
  //     });
  //     console.log("001 - 1");
  //     editMode(1);
  //   } else {
  //     setFormInput({
  //       editField: false,
  //       editValue: { name: name, value: value },
  //     });
  //     console.log("002 - -1");
  //     editMode(-1);
  //   }
  // }, [nameDropdown, name, value]);

  const handleEditField = () => {
    setFormInput({
      ...formInput,
      editField: true,
      editValue: { name: name, value: value },
    });
    console.log("003 - 1");
    editMode(1);
  };

  const handleSaveField = () => {
    saveFieldValue(name, formInput.editValue);
    setFormInput({
      ...formInput,
      editField: false,
    });
    console.log("004 - -1");
    editMode(-1);
  };

  const handleResetField = () => {
    setFormInput({ ...formInput, editField: false });
    console.log("005 - -1");
    editMode(-1);
  };

  const handleDeleteField = () => {
    deleteFieldValue(name);
    setFormInput({
      ...formInput,
      editField: false,
    });
    console.log("006 - -1");
    editMode(-1);
  };

  const handleFieldChange = (e1) => {
    resetValidation();

    setFormInput({
      ...formInput,
      editValue: { ...formInput.editValue, [e1.target.name]: e1.target.value },
    });
  };

  return (
    <div className="mb-2">
      <InputGroup size="sm">
        {formInput.editField && parameter && (
          <InputGroup.Text className="bg-secondary text-white pe-none">
            key
          </InputGroup.Text>
        )}
        {formInput.editField && parameter && (
          <Form.Control
            id={`form-name-${name}`}
            type="text"
            value={!formInput.editField ? value : formInput.editValue.name}
            readOnly={!formInput.editField ? true : !parameter ? true : false}
            onChange={handleFieldChange}
            name="name"
            className="font-monospace focus-ring"
          />
        )}

        <InputGroup.Text className="bg-secondary text-white pe-none">
          {formInput.editField && parameter ? "value" : name}
        </InputGroup.Text>

        <Form.Control
          id={`form-value-${name}`}
          type={formInput.editField ? type : "text"}
          value={!formInput.editField ? value : formInput.editValue.value}
          readOnly={!formInput.editField}
          onChange={handleFieldChange}
          name="value"
          className="font-monospace focus-ring"
          required={["API Name", "Domain"].includes(name) ? true : false}
        />
        {!formInput.editField && (
          <Button
            variant="dark"
            onClick={handleEditField}
            name={name}
            className="d-flex justify-content-center align-items-center"
            title="Edit"
          >
            <Pencil className="pe-none" />
          </Button>
        )}
        {formInput.editField && (
          <Button
            variant="success"
            onClick={handleSaveField}
            name={name}
            className="d-flex justify-content-center align-items-center"
            title="Save"
          >
            <Check2Square className="pe-none" />
          </Button>
        )}
        {formInput.editField && !newParameter && (
          <Button
            variant="dark"
            onClick={handleResetField}
            name={name}
            className="d-flex justify-content-center align-items-center"
            title="Reset"
          >
            <ArrowClockwise className="pe-none" />
          </Button>
        )}
        {formInput.editField && parameter && (
          <Button
            variant="dark"
            onClick={handleDeleteField}
            name={name}
            className="d-flex justify-content-center align-items-center"
            title="Delete"
          >
            <Trash3 className="pe-none" />
          </Button>
        )}
      </InputGroup>
      {fieldValidation && !formInput.editValue.value && !value && (
        <div className="validation-feedback pt-1 ps-2">{name} is required.</div>
      )}
    </div>
  );
};

export default FormField;
