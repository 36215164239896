import React, { useState, useEffect } from "react";

import { useQuery, useMutation } from "@apollo/client";
import { APPS } from "../utils/queries";

// import { useSelector } from "react-redux";
import { useSelector, useDispatch } from "react-redux";

import {
  showApps,
  addApp,
  updateApp,
  removeApp,
} from "../store/apps/appsSlice";

//bootstrap
import {
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Button,
  Row,
  Col,
  Modal,
  Fade,
  // Badge,
  // CloseButton,
} from "react-bootstrap";

import { ADD_APP, UPDATE_APP, REMOVE_APP } from "../utils/mutations";

import { dateFormat } from "../utils/helpers";

const EditApps = () => {
  const appList = useSelector((state) => state.apps.value);
  console.log(appList);
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);

  // explicit fields to add as editable form fields to the DOM. Omits additional fields from db.
  const [app, setApp] = useState({
    changes: { appListIndex: "", fields: [] },
    data: {
      name: "",
      releaseDate: "",
      version: "",
      versionDate: "",
      category1: "",
      category2: "",
      keywords: [],
      description: "",
      cardDescription: "",
      cardImg: "",
      instructions: "",
      appLocation: "",
    },
  });

  // TODO: Temporary solution. Add to store or db document to have list dynamic.
  const requiredFields = [
    "name",
    "category1",
    "category2",
    "description",
    "cardDescription",
  ];

  // const [formUpdates, setFormUpdates] = useState({
  //   initialValues: { ...app },
  //   changes: {},
  // });

  const [showModalFormChange, setShowModalFormChange] = useState({
    modalPrompt: false,
    dropdownKey: "",
    bypass: false,
  });

  const { loading, data, error } = useQuery(APPS);
  const [addDbApp] = useMutation(ADD_APP);
  const [updateDbApp] = useMutation(UPDATE_APP);
  const [removeDbApp] = useMutation(REMOVE_APP);

  // useEffect for loading appList data
  useEffect(() => {
    if (loading) {
      console.log("loading");
      console.log(loading);
    }

    if (error) {
      console.log("error");
      console.log(error);
    }

    if (data) {
      dispatch(showApps(data.apps));
    }
  }, [data, loading, error, dispatch]);

  // useEffect for proceeding with app change with unsaved changes after modal state updates
  useEffect(() => {
    if (app.changes.fields.length && showModalFormChange.bypass) {
      setApp({ ...app, changes: { ...app.changes, fields: [] } });
      setShowModalFormChange({
        ...showModalFormChange,
        modalPrompt: false,
        dropdownKey: "",
        bypass: false,
      });
      handleAppDropdown(showModalFormChange.dropdownKey);
    }
  }, [showModalFormChange.bypass]);

  const handleAppDropdown = (eventKey) => {
    if (!eventKey) {
      return;
    }

    setValidated(false);

    if (app.changes.fields.length > 0) {
      if (!showModalFormChange.bypass) {
        setShowModalFormChange({
          ...showModalFormChange,
          modalPrompt: true,
          dropdownKey: eventKey,
        });
        return;
      }
    }
    let updateList = {};

    // if (eventKey === "new") {
    //   Object.keys(app.data).forEach((keyName) => {
    //     updateList = {
    //       ...updateList,
    //       [keyName]: keyName === "keywords" ? [] : "",
    //     };
    //   });

    //   setApp({
    //     ...app,
    //     changes: { ...app.changes, appListIndex: eventKey, fields: [] },
    //     data: { ...app.data, ...updateList },
    //   });
    //   return;
    // }

    // creates object with only the fields explicitly listed in the 'app' state
    if (eventKey !== "new") {
      Object.keys(appList[eventKey]).forEach((keyName) => {
        if (keyName in app.data) {
          updateList = { ...updateList, [keyName]: appList[eventKey][keyName] };
        }
      });
    }

    // Adding fields in 'app' state that do not exist in the app db document. This assists with clearing unsaved edited fields when switching between apps.
    Object.keys(app.data).forEach((keyName) => {
      if (eventKey === "new" || !(keyName in appList[eventKey])) {
        updateList = {
          ...updateList,
          [keyName]: keyName === "keywords" ? [] : "",
        };
      }
    });

    // updates fields in app state without removing app state fields if they don't exist in the db results
    setApp({
      ...app,
      changes: { ...app.changes, appListIndex: eventKey, fields: [] },
      data: { ...app.data, ...updateList },
    });
  };

  // Updates field values and form field's validity
  const handleFormChange = (event) => {
    const { name, value } = event.target;

    console.log(name);
    console.log(value);

    setValidated(false);

    // TODO: refactor
    if (app.changes.appListIndex !== "new") {
      if (name in appList[app.changes.appListIndex]) {
        if (appList[app.changes.appListIndex][name] !== value) {
          setApp({
            ...app,
            changes: {
              ...app.changes,
              fields: app.changes.fields.includes(name)
                ? [...app.changes.fields]
                : [...app.changes.fields, name],
            },
            data: {
              ...app.data,
              [name]:
                name === "keywords"
                  ? value.split(",").map((keyword) => {
                      return keyword.trim();
                    })
                  : value,
            },
          });
        } else {
          setApp({
            ...app,
            changes: {
              ...app.changes,
              fields: app.changes.fields.filter((f) => f !== name),
            },
            data: {
              ...app.data,
              [name]:
                name === "keywords"
                  ? value.split(",").map((keyword) => {
                      return keyword.trim();
                    })
                  : value,
            },
          });
        }
      } else {
        if (value !== "") {
          setApp({
            ...app,
            changes: {
              ...app.changes,
              fields: app.changes.fields.includes(name)
                ? [...app.changes.fields]
                : [...app.changes.fields, name],
            },
            data: {
              ...app.data,
              [name]:
                name === "keywords"
                  ? value.split(",").map((keyword) => {
                      return keyword.trim();
                    })
                  : value,
            },
          });
        } else {
          setApp({
            ...app,
            changes: {
              ...app.changes,
              fields: app.changes.fields.filter((f) => f !== name),
            },
            data: {
              ...app.data,
              [name]:
                name === "keywords"
                  ? value.split(",").map((keyword) => {
                      return keyword.trim();
                    })
                  : value,
            },
          });
        }
      }
    } else {
      if (value !== "") {
        setApp({
          ...app,
          changes: {
            ...app.changes,
            fields: app.changes.fields.includes(name)
              ? [...app.changes.fields]
              : [...app.changes.fields, name],
          },
          data: {
            ...app.data,
            [name]:
              name === "keywords"
                ? value.split(",").map((keyword) => {
                    return keyword.trim();
                  })
                : value,
          },
        });
      } else {
        setApp({
          ...app,
          changes: {
            ...app.changes,
            fields: app.changes.fields.filter((f) => f !== name),
          },
          data: {
            ...app.data,
            [name]:
              name === "keywords"
                ? value.split(",").map((keyword) => {
                    return keyword.trim();
                  })
                : value,
          },
        });
      }
    }
  };

  const handleFormSubmit = async () => {
    const form = document.getElementById("appForm");
    if (!app.changes.fields.length) {
      return;
    }

    if (form.checkValidity() === false) {
      console.log("formValidity false");
      console.log(form.checkValidity());
      setValidated(true);
      return;
    }
    setValidated(false);

    // TODO: possible refactor opportunity
    let results;
    try {
      switch (app.changes.appListIndex) {
        case "new":
          console.log("new");
          // add app to database
          results = await addDbApp({
            variables: {
              ...app.data,
            },
          });
          console.log(results);
          // add app to store
          dispatch(addApp({ index: "new", data: results }));
          break;
        case "remove":
          console.log("remove");
          // remove app in database
          results = await removeDbApp({
            variables: {
              id: appList[app.changes.appListIndex]._id,
            },
          });
          console.log(results);

          // update app in store
          dispatch(removeApp({ index: app.changes.appListIndex }));
          break;
        default:
          console.log("update");
          // update app in database
          results = await updateDbApp({
            variables: {
              ...app.data,
              id: appList[app.changes.appListIndex]._id,
            },
          });
          console.log("appList");
          console.log(appList);

          console.log("results");
          console.log(results);
          console.log(results.data.updateApp);

          // update app in store
          dispatch(
            updateApp({
              index: app.changes.appListIndex,
              data: results.data.updateApp,
            })
          );
          break;
      }
      // if (app.changes.appListIndex === "new") {
      //   console.log("new");

      //   // add app to database
      //   const results = await addDbApp({
      //     variables: {
      //       ...app.data,
      //     },
      //   });
      //   console.log(results);

      //   // add app to store
      //   dispatch(addApp({ index: "new", data: results }));
      // } else if (app.changes.appListIndex === "remove") {
      //   console.log("remove");
      //   // remove app in database
      //   const results = await removeDbApp({
      //     variables: {
      //       id: appList[app.changes.appListIndex]._id,
      //     },
      //   });
      //   console.log(results);

      //   // update app in store
      //   dispatch(removeApp({ index: app.changes.appListIndex }));
      // } else {
      //   console.log("update");
      //   // update app in database
      //   const results = await updateDbApp({
      //     variables: {
      //       ...app.data,
      //       id: appList[app.changes.appListIndex]._id,
      //     },
      //   });
      //   console.log(results);

      //   // update app in store
      //   dispatch(updateApp({ index: app.changes.appListIndex, data: results }));
      // }

      // reset form status
      setApp({
        ...app,
        changes: { ...app.changes, fields: [] },
      });

      // TODO: add temporary success message
    } catch (e) {
      console.log("error");
      console.error(e);
    }
    return;
  };

  const handleReset = () => {
    setValidated(false);

    if (app.changes.fields) {
      let resetValues = {};
      app.changes.fields.forEach((fieldName) => {
        resetValues = {
          ...resetValues,
          [fieldName]:
            app.changes.appListIndex === "new"
              ? ""
              : fieldName in appList[app.changes.appListIndex]
              ? appList[app.changes.appListIndex][fieldName]
              : "",
        };
      });
      setApp({
        ...app,
        changes: {
          ...app.changes,
          fields: [],
        },
        data: { ...app.data, ...resetValues },
      });
    }
  };

  console.log(app);

  console.log(showModalFormChange);

  console.log(dateFormat(app.data.releaseDate));

  return (
    <>
      <Container className="text-light mb-1">
        <h3>EDIT APP</h3>
        <Row>
          <Col>
            <DropdownButton
              id="dropdown-button-dark-example2"
              variant="secondary"
              menuVariant="dark"
              title={
                !app.changes.appListIndex
                  ? "Select App "
                  : app.changes.appListIndex === "new" && !app.data.name
                  ? "New App "
                  : app.data.name
              }
              className="d-inline me-2"
              onSelect={handleAppDropdown}
              size="sm"
            >
              {loading ? (
                <Dropdown.Item href="#/action-1" active>
                  Loading...
                </Dropdown.Item>
              ) : (
                <span>
                  <span className="text-white">
                    <Dropdown.Item key={"new"} eventKey={"new"}>
                      New App...
                    </Dropdown.Item>
                  </span>
                  <Dropdown.Divider />

                  {appList.length ? (
                    <span className="text-white">
                      {appList.map(({ name }, i) => (
                        <Dropdown.Item key={name} eventKey={i}>
                          {name}
                        </Dropdown.Item>
                      ))}
                    </span>
                  ) : (
                    <div className="text-white">No Apps</div>
                  )}
                </span>
              )}
            </DropdownButton>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <Fade in={app.changes.fields.length > 0}>
                <Button
                  className="d-inline me-2"
                  size="sm"
                  variant="secondary"
                  // type="submit"
                  onClick={handleFormSubmit}
                  disabled={!app.changes.fields.length}
                >
                  Save Changes
                </Button>
              </Fade>

              <Fade in={app.changes.fields.length > 0}>
                <Button
                  className="d-inline me-2"
                  size="sm"
                  onClick={handleReset}
                  variant="secondary"
                  disabled={!app.changes.fields.length}
                >
                  Reset
                </Button>
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="text-light py-1">
        <div className="border-bottom mb-3 text-end">
          <Fade in={app.changes.fields.length > 0}>
            <span style={{ fontSize: ".875rem" }}>*required fields</span>
          </Fade>
        </div>
        {app.changes.appListIndex ? (
          <Form id="appForm" noValidate validated={validated}>
            {/* All fields from appList(db data) that will be read-only */}
            {app.changes.appListIndex !== "new" &&
              Object.keys(appList[app.changes.appListIndex]).map(
                (keyName) =>
                  !(keyName in app.data) && (
                    <Row key={keyName}>
                      <Col
                        sm={3}
                        className="d-flex justify-content-sm-end fw-bold "
                      >
                        {keyName}:
                      </Col>
                      <Col sm={9} className="mb-sm-0 mb-2 fst-italic">
                        {[
                          "createdAt",
                          "updatedAt",
                          "releaseDate",
                          "versionDate",
                        ].includes(keyName)
                          ? dateFormat(
                              appList[app.changes.appListIndex][keyName]
                            )
                          : appList[app.changes.appListIndex][keyName]}
                      </Col>
                    </Row>
                  )
              )}
            {app.changes.appListIndex !== "new" && (
              <div className="border-bottom my-3"></div>
            )}
            {/* All fields from 'app' state that will editable. Also includes fields that the db document did not have. */}
            {Object.keys(app.data).map(
              (keyName) =>
                keyName !== "appListIndex" && (
                  <Form.Group
                    key={keyName}
                    className="mb-3"
                    controlId={keyName}
                  >
                    <Row>
                      <Col
                        sm={3}
                        className="d-flex justify-content-sm-end fw-bold"
                      >
                        <Form.Label>
                          {requiredFields.includes(keyName) && "*"}
                          {keyName}:
                        </Form.Label>
                      </Col>
                      <Col sm={9} className="position-relative">
                        {keyName !== "category1" ? (
                          <Form.Control
                            className="mb-sm-0 mb-1"
                            name={keyName}
                            type={
                              ["releaseDate", "versionDate"].includes(keyName)
                                ? "date"
                                : // ["cardImg", "appLocation"].includes(keyName)
                                  // ? "url"
                                  "text"
                            }
                            size="sm"
                            as={
                              keyName === "description" ? "textarea" : "input"
                            }
                            // defaultValue={app[keyName]}
                            value={
                              // ["releaseDate", "versionDate"].includes(keyName)
                              //   ? `${
                              //       app.data[keyName].split(" ")[2]
                              //     }-${app.data[keyName]
                              //       .split(" ")[1]
                              //       .substring(0, 2)}-${
                              //       [
                              //         "Jan",
                              //         "Feb",
                              //         "Mar",
                              //         "Apr",
                              //         "May",
                              //         "Jun",
                              //         "Jul",
                              //         "Aug",
                              //         "Sep",
                              //         "Oct",
                              //         "Nov",
                              //         "Dec",
                              //       ].indexOf(app.data[keyName].split(" ")[0]) -
                              //       1
                              //     }`
                              // :
                              app.data[keyName]
                            }
                            // value={formUpdates.keyName}
                            onChange={handleFormChange}
                            // TODO: refactor to function or state update during change handle
                            // isValid={
                            //   !app.changes.fields.includes(keyName)
                            //     ? false
                            //     : requiredFields.includes(keyName) &&
                            //       app.data[keyName] === ""
                            //     ? false
                            //     : true
                            // }
                            // TODO: refactor to function or state update during change handle
                            // isInvalid={
                            //   requiredFields.includes(keyName) &&
                            //   app.data[keyName] === ""
                            //     ? true
                            //     : false
                            // }
                            required={requiredFields.includes(keyName)}
                          />
                        ) : (
                          <Form.Select
                            className="mb-sm-0 mb-1"
                            name={keyName}
                            size="sm"
                            value={app.data[keyName]}
                            onChange={handleFormChange}
                          >
                            <option value="Games">Games</option>
                            <option value="Tools">Tools</option>
                          </Form.Select>
                        )}
                        {requiredFields.includes(keyName) && (
                          <Form.Control.Feedback
                            tooltip
                            type="invalid"
                            style={{
                              top: "5%",
                              right: "1rem",
                              paddingTop: "1px",
                              paddingBottom: "1px",
                            }}
                          >
                            {keyName} is a required field
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      {/* {keyName === "keywords" && (
                    <Col xs={6}>
                      {app[keyName].map((keyword) => (
                        <Badge
                          key={keyword}
                          bg="secondary"
                          className="me-1 mb-1"
                        >
                          {keyword}
                          <CloseButton className="ms-1" />
                        </Badge>
                      ))}
                    </Col>
                  )} */}
                    </Row>
                  </Form.Group>
                )
            )}
          </Form>
        ) : (
          <div></div>
        )}
        {app.changes.appListIndex && <div className="border-bottom my-3"></div>}
      </Container>
      {/* unsaved changes modal */}
      <Modal
        size="md"
        show={showModalFormChange.modalPrompt}
        onHide={() =>
          setShowModalFormChange({
            modalPrompt: false,
            dropdownKey: "",
            bypass: false,
          })
        }
        aria-labelledby="formChange-modal"
        autoFocus="true"
        scrollable="true"
      >
        {/* tab container to do either signup or login component */}
        <Modal.Header closeButton>
          <Modal.Title as="h5" id="formChange-modal" className="w-100">
            Changes not saved!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Changes to the form were made and not saved.</Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() =>
              setShowModalFormChange({
                ...showModalFormChange,
                modalPrompt: false,
                dropdownKey: "",
              })
            }
          >
            Close
          </Button>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() =>
              setShowModalFormChange({
                ...showModalFormChange,
                modalPrompt: false,
                bypass: true,
              })
            }
            // onClick={() => {
            // setShowModalFormChange({
            //   modalPrompt: false,
            //   dropdownKey: null,
            // }),
            //   forceUpdate(
            //     setApp(
            //       {},
            //       handleAppDropdown(showModalFormChange.dropdownKey)
            //     )
            //   );
            // }}
          >
            Continue without saving
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditApps;
