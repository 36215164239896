//
// --- profile data
//
export const getProfileData = () => {
  const profileData = localStorage.getItem("profileData")
    ? JSON.parse(localStorage.getItem("profileData"))
    : {};

  return profileData;
};

export const saveProfileData = (profileData) => {
  localStorage.setItem("profileData", JSON.stringify(profileData));
};

export const updateProfileData = (name, value) => {
  const profileData = localStorage.getItem("profileData")
    ? JSON.parse(localStorage.getItem("profileData"))
    : {};

  // console.log("localStorage-updateProfileData-name");
  // console.log(name);
  // console.log("localStorage-updateProfileData-value");
  // console.log(value);
  // console.log("localStorage-updateProfileData-profileData");
  // console.log(profileData);

  const updatedProfileData = { ...profileData, [name]: value };

  // console.log("localStorage-updateProfileData-updatedProfileData");
  // console.log(updatedProfileData);

  localStorage.setItem("profileData", JSON.stringify(updatedProfileData));
};

export const removeProfileData = () => {
  localStorage.removeItem("profileData");
};

//
// --- app data
//
export const getAppData = () => {
  const appData = localStorage.getItem("appData")
    ? JSON.parse(localStorage.getItem("appData"))
    : {};

  return appData;
};

export const getSingleAppData = (name) => {
  const appsData = localStorage.getItem("appData")
    ? JSON.parse(localStorage.getItem("appData"))
    : {};

  const appData = appsData[name] ? appsData[name] : {};

  return appData;
};

export const saveAppData = (name, appData) => {
  console.log("saveAppData", appData);
  localStorage.setItem("appData", JSON.stringify({ [name]: appData }));
};

export const updateAppData = (name, value) => {
  const appData = localStorage.getItem("appData")
    ? JSON.parse(localStorage.getItem("appData"))
    : {};

  const updatedAppData = { ...appData, [name]: value };

  localStorage.setItem("appData", JSON.stringify(updatedAppData));
};

export const removeSingleAppData = (name) => {
  let appData = localStorage.getItem("appData")
    ? JSON.parse(localStorage.getItem("appData"))
    : {};

  // const updatedAppData = { ...appData, [name]: value };
  delete appData[name];

  localStorage.setItem("appData", JSON.stringify(appData));
};

export const removeAllAppData = () => {
  localStorage.removeItem("appData");
};
