import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useQuery } from "@apollo/client";
import { APPS } from "../../utils/queries";
import { showApps } from "./appsSlice";

import AppCard from "../../components/AppCard";

export const Apps = () => {
  const appList = useSelector((state) => state.apps.value);
  const dispatch = useDispatch();

  const { loading, data, error } = useQuery(APPS);

  useEffect(() => {
    if (loading) {
      console.log("loading");
      console.log(loading);
    }

    if (error) {
      console.log("error");
      console.log(error);
    }

    if (data) {
      console.log("apps-data");
      console.log(data.apps);
      dispatch(showApps(data.apps));
    }
  }, [data, loading, error, dispatch]);

  return (
    <>
      {loading ? (
        <div>loading...</div>
      ) : (
        <span>
          {appList.length ? (
            <span className="text-white">
              {appList.map((app) => (
                <AppCard
                  key={app._id}
                  appId={app._id}
                  cardTitle={app.name}
                  cardText={app.cardDescription}
                  cardFooter={app.createdAt}
                  cardLink={app.appLocation}
                />
              ))}
            </span>
          ) : (
            <div className="text-white">No Apps</div>
          )}
        </span>
      )}
    </>
  );
};
