import { createSlice } from "@reduxjs/toolkit";

import Auth from "../../utils/auth";

// local storage
import { getProfileData } from "../../utils/localStorage";

const authLoggedIn = Auth.loggedIn();
const localStorageProfile = authLoggedIn ? getProfileData() : {};
// console.log("profileSlice");
// console.log(localStorageProfile);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    name: localStorageProfile.name || "",
    email: localStorageProfile.email || "",
    createdAt: localStorageProfile.createdAt || "",
    updatedAt: localStorageProfile.updatedAt || "",
    favoriteApps: localStorageProfile.favoriteApps || [],
    comments: localStorageProfile.comments || [],
    role: localStorageProfile.role || "",
    avatar: localStorageProfile.avatar || "",
    theme: localStorageProfile.theme || "",
    bio: localStorageProfile.bio || "",
    github: localStorageProfile.github || "",
    contactEmail: localStorageProfile.contactEmail || "",
  },
  reducers: {
    loggedIn: (state, action) => {
      // console.log("loggedIn");
      // console.log(action.payload);
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.createdAt = action.payload.createdAt;
      state.updatedAt = action.payload.updatedAt;
      state.favoriteApps = action.payload.favoriteApps.length
        ? action.payload.favoriteApps.map((app) => app._id)
        : [];
      state.comments = action.payload.comments.map((comment) => comment._id);
      state.role = action.payload.role;
      state.avatar = action.payload.avatar;
      state.theme = action.payload.theme;
      state.bio = action.payload.bio;
      state.github = action.payload.github;
      state.contactEmail = action.payload.contactEmail;
    },
    loggedOut: (state, action) => {
      // console.log("loggedOut");
      state.name = "";
      state.email = "";
      state.createdAt = "";
      state.updatedAt = "";
      state.favoriteApps = [];
      state.comments = [];
      state.role = "";
      state.avatar = "";
      state.theme = "";
      state.bio = "";
      state.github = "";
      state.contactEmail = "";
    },
    addFavorite: (state, action) => {
      return {
        ...state,
        favoriteApps: [...state.favoriteApps, action.payload],
      };
    },
    removeFavorite: (state, action) => {
      return {
        ...state,
        favoriteApps: state.favoriteApps.filter((id) => {
          return id !== action.payload && id !== undefined;
        }),
      };
    },
  },
});

export const { loggedIn, loggedOut, addFavorite, removeFavorite } =
  profileSlice.actions;

export default profileSlice.reducer;
