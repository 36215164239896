export const countryRegionLocales = {
  regionCodes: {
    sat: "Santali",
    aa: "Afar",
    ab: "Abkhazian",
    ace: "Achinese",
    ach: "Acoli",
    ada: "Adangme",
    ady: "Adygei,Adyghe",
    ae: "Avestan",
    af: "Afrikaans",
    afa: "Afro-Asiatic languages",
    afh: "Afrihili",
    ain: "Ainu",
    ak: "Akan",
    akk: "Akkadian",
    ale: "Aleut",
    alg: "Algonquian languages",
    alt: "Southern Altai",
    am: "Amharic",
    an: "Aragonese",
    ang: "English, Old (ca.450-1100)",
    anp: "Angika",
    apa: "Apache languages",
    ar: "Arabic",
    arc: "Imperial Aramaic (700-300 BCE),Official Aramaic (700-300 BCE)",
    arn: "Mapuche,Mapudungun",
    arp: "Arapaho",
    art: "Artificial languages",
    arw: "Arawak",
    as: "Assamese",
    ast: "Asturian,Asturleonese,Bable,Leonese",
    ath: "Athapascan languages",
    aus: "Australian languages",
    av: "Avaric",
    awa: "Awadhi",
    ay: "Aymara",
    az: "Azerbaijani",
    ba: "Bashkir",
    bad: "Banda languages",
    bai: "Bamileke languages",
    bal: "Baluchi",
    ban: "Balinese",
    bas: "Basa",
    bat: "Baltic languages",
    be: "Belarusian",
    bej: "Bedawiyet,Beja",
    bem: "Bemba",
    ber: "Berber languages",
    bg: "Bulgarian",
    bh: "Bihari languages",
    bho: "Bhojpuri",
    bi: "Bislama",
    bik: "Bikol",
    bin: "Bini,Edo",
    bla: "Siksika",
    bm: "Bambara",
    bn: "Bengali",
    bnt: "Bantu languages",
    bo: "Tibetan",
    br: "Breton",
    bra: "Braj",
    bs: "Bosnian",
    btk: "Batak languages",
    bua: "Buriat",
    bug: "Buginese",
    byn: "Bilin,Blin",
    ca: "Catalan,Valencian",
    cad: "Caddo",
    cai: "Central American Indian languages",
    car: "Galibi Carib",
    cau: "Caucasian languages",
    ce: "Chechen",
    ceb: "Cebuano",
    cel: "Celtic languages",
    ch: "Chamorro",
    chb: "Chibcha",
    chg: "Chagatai",
    chk: "Chuukese",
    chm: "Mari",
    chn: "Chinook jargon",
    cho: "Choctaw",
    chp: "Chipewyan,Dene Suline",
    chr: "Cherokee",
    chy: "Cheyenne",
    cmc: "Chamic languages",
    cnr: "Montenegrin",
    co: "Corsican",
    cop: "Coptic",
    cpe: "Creoles and pidgins, English based",
    cpf: "Creoles and pidgins, French-based",
    cpp: "Creoles and pidgins, Portuguese-based",
    cr: "Cree",
    crh: "Crimean Tatar,Crimean Turkish",
    crp: "Creoles and pidgins",
    cs: "Czech",
    csb: "Kashubian",
    cu: "Church Slavic,Church Slavonic,Old Bulgarian,Old Church Slavonic,Old Slavonic",
    cus: "Cushitic languages",
    cv: "Chuvash",
    cy: "Welsh",
    da: "Danish",
    dak: "Dakota",
    dar: "Dargwa",
    day: "Land Dayak languages",
    de: "German",
    del: "Delaware",
    den: "Slave (Athapascan)",
    dgr: "Dogrib",
    din: "Dinka",
    doi: "Dogri",
    dra: "Dravidian languages",
    dsb: "Lower Sorbian",
    dua: "Duala",
    dum: "Dutch, Middle (ca.1050-1350)",
    dv: "Dhivehi,Divehi,Maldivian",
    dyu: "Dyula",
    dz: "Dzongkha",
    ee: "Ewe",
    efi: "Efik",
    egy: "Egyptian (Ancient)",
    eka: "Ekajuk",
    el: "Greek, Modern (1453-)",
    elx: "Elamite",
    en: "English",
    enm: "English, Middle (1100-1500)",
    eo: "Esperanto",
    es: "Castilian,Spanish",
    et: "Estonian",
    eu: "Basque",
    ewo: "Ewondo",
    fa: "Persian",
    fan: "Fang",
    fat: "Fanti",
    ff: "Fulah",
    fi: "Finnish",
    fil: "Filipino,Pilipino",
    fiu: "Finno-Ugrian languages",
    fj: "Fijian",
    fo: "Faroese",
    fon: "Fon",
    fr: "French",
    frm: "French, Middle (ca.1400-1600)",
    fro: "French, Old (842-ca.1400)",
    frr: "Northern Frisian",
    frs: "Eastern Frisian",
    fur: "Friulian",
    fy: "Western Frisian",
    ga: "Irish",
    gaa: "Ga",
    gay: "Gayo",
    gba: "Gbaya",
    gd: "Gaelic,Scottish Gaelic",
    gem: "Germanic languages",
    gez: "Geez",
    gil: "Gilbertese",
    gl: "Galician",
    gmh: "German, Middle High (ca.1050-1500)",
    gn: "Guarani",
    goh: "German, Old High (ca.750-1050)",
    gon: "Gondi",
    gor: "Gorontalo",
    got: "Gothic",
    grb: "Grebo",
    grc: "Greek, Ancient (to 1453)",
    gsw: "Alemannic,Alsatian,Swiss German",
    gu: "Gujarati",
    gv: "Manx",
    gwi: "Gwich'in",
    ha: "Hausa",
    hai: "Haida",
    haw: "Hawaiian",
    he: "Hebrew",
    hi: "Hindi",
    hil: "Hiligaynon",
    him: "Himachali languages,Western Pahari languages",
    hit: "Hittite",
    hmn: "Hmong,Mong",
    ho: "Hiri Motu",
    hr: "Croatian",
    hsb: "Upper Sorbian",
    ht: "Haitian,Haitian Creole",
    hu: "Hungarian",
    hup: "Hupa",
    hy: "Armenian",
    hz: "Herero",
    ia: "Interlingua (International Auxiliary Language Association)",
    iba: "Iban",
    id: "Indonesian",
    ie: "Interlingue,Occidental",
    ig: "Igbo",
    ii: "Nuosu,Sichuan Yi",
    ijo: "Ijo languages",
    ik: "Inupiaq",
    ilo: "Iloko",
    inc: "Indic languages",
    ine: "Indo-European languages",
    inh: "Ingush",
    io: "Ido",
    ira: "Iranian languages",
    iro: "Iroquoian languages",
    is: "Icelandic",
    it: "Italian",
    iu: "Inuktitut",
    ja: "Japanese",
    jbo: "Lojban",
    jpr: "Judeo-Persian",
    jrb: "Judeo-Arabic",
    jv: "Javanese",
    ka: "Georgian",
    kaa: "Kara-Kalpak",
    kab: "Kabyle",
    kac: "Jingpho,Kachin",
    kam: "Kamba",
    kar: "Karen languages",
    kaw: "Kawi",
    kbd: "Kabardian",
    kg: "Kongo",
    kha: "Khasi",
    khi: "Khoisan languages",
    kho: "Khotanese,Sakan",
    ki: "Gikuyu,Kikuyu",
    kj: "Kuanyama,Kwanyama",
    kk: "Kazakh",
    kl: "Greenlandic,Kalaallisut",
    km: "Central Khmer",
    kmb: "Kimbundu",
    kn: "Kannada",
    ko: "Korean",
    kok: "Konkani",
    kos: "Kosraean",
    kpe: "Kpelle",
    kr: "Kanuri",
    krc: "Karachay-Balkar",
    krl: "Karelian",
    kro: "Kru languages",
    kru: "Kurukh",
    ks: "Kashmiri",
    ku: "Kurdish",
    kum: "Kumyk",
    kut: "Kutenai",
    kv: "Komi",
    kw: "Cornish",
    ky: "Kirghiz,Kyrgyz",
    la: "Latin",
    lad: "Ladino",
    lah: "Lahnda",
    lam: "Lamba",
    lb: "Letzeburgesch,Luxembourgish",
    lez: "Lezghian",
    lg: "Ganda",
    li: "Limburgan,Limburger,Limburgish",
    ln: "Lingala",
    lo: "Lao",
    lol: "Mongo",
    loz: "Lozi",
    lt: "Lithuanian",
    lu: "Luba-Katanga",
    lua: "Luba-Lulua",
    lui: "Luiseno",
    lun: "Lunda",
    luo: "Luo (Kenya and Tanzania)",
    lus: "Lushai",
    lv: "Latvian",
    mad: "Madurese",
    mag: "Magahi",
    mai: "Maithili",
    mak: "Makasar",
    man: "Mandingo",
    map: "Austronesian languages",
    mas: "Masai",
    mdf: "Moksha",
    mdr: "Mandar",
    men: "Mende",
    mg: "Malagasy",
    mga: "Irish, Middle (900-1200)",
    mh: "Marshallese",
    mi: "Maori",
    mic: "Mi'kmaq,Micmac",
    min: "Minangkabau",
    mis: "Uncoded languages",
    mk: "Macedonian",
    mkh: "Mon-Khmer languages",
    ml: "Malayalam",
    mn: "Mongolian",
    mnc: "Manchu",
    mni: "Manipuri",
    mno: "Manobo languages",
    moh: "Mohawk",
    mos: "Mossi",
    mr: "Marathi",
    ms: "Malay",
    mt: "Maltese",
    mul: "Multiple languages",
    mun: "Munda languages",
    mus: "Creek",
    mwl: "Mirandese",
    mwr: "Marwari",
    my: "Burmese",
    myn: "Mayan languages",
    myv: "Erzya",
    na: "Nauru",
    nah: "Nahuatl languages",
    nai: "North American Indian languages",
    nap: "Neapolitan",
    nb: "Bokmål, Norwegian,Norwegian Bokmål",
    nd: "Ndebele, North,North Ndebele",
    nds: "German, Low,Low German,Low Saxon,Saxon, Low",
    ne: "Nepali",
    new: "Nepal Bhasa,Newari",
    ng: "Ndonga",
    nia: "Nias",
    nic: "Niger-Kordofanian languages",
    niu: "Niuean",
    nl: "Dutch,Flemish",
    nn: "Norwegian Nynorsk,Nynorsk, Norwegian",
    no: "Norwegian",
    nog: "Nogai",
    non: "Norse, Old",
    nqo: "N'Ko",
    nr: "Ndebele, South,South Ndebele",
    nso: "Northern Sotho,Pedi,Sepedi,Sotho, Northern",
    nub: "Nubian languages",
    nv: "Navaho,Navajo",
    nwc: "Classical Nepal Bhasa,Classical Newari,Old Newari",
    ny: "Chewa,Chichewa,Nyanja",
    nym: "Nyamwezi",
    nyn: "Nyankole",
    nyo: "Nyoro",
    nzi: "Nzima",
    oc: "Occitan (post 1500)",
    oj: "Ojibwa",
    om: "Oromo",
    or: "Oriya",
    os: "Ossetian,Ossetic",
    osa: "Osage",
    ota: "Turkish, Ottoman (1500-1928)",
    oto: "Otomian languages",
    pa: "Panjabi,Punjabi",
    paa: "Papuan languages",
    pag: "Pangasinan",
    pal: "Pahlavi",
    pam: "Kapampangan,Pampanga",
    pap: "Papiamento",
    pau: "Palauan",
    peo: "Persian, Old (ca.600-400 B.C.)",
    phi: "Philippine languages",
    phn: "Phoenician",
    pi: "Pali",
    pl: "Polish",
    pon: "Pohnpeian",
    pra: "Prakrit languages",
    pro: "Occitan, Old (to 1500),Provençal, Old (to 1500)",
    ps: "Pashto,Pushto",
    pt: "Portuguese",
    "qaa-qtz": "Reserved for local use",
    qu: "Quechua",
    raj: "Rajasthani",
    rap: "Rapanui",
    rar: "Cook Islands Maori,Rarotongan",
    rm: "Romansh",
    rn: "Rundi",
    ro: "Moldavian,Moldovan,Romanian",
    roa: "Romance languages",
    rom: "Romany",
    ru: "Russian",
    rup: "Aromanian,Arumanian,Macedo-Romanian",
    rw: "Kinyarwanda",
    sa: "Sanskrit",
    sad: "Sandawe",
    sah: "Yakut",
    sai: "South American Indian languages",
    sal: "Salishan languages",
    sam: "Samaritan Aramaic",
    sas: "Sasak",
    sc: "Sardinian",
    scn: "Sicilian",
    sco: "Scots",
    sd: "Sindhi",
    se: "Northern Sami",
    sel: "Selkup",
    sem: "Semitic languages",
    sg: "Sango",
    sga: "Irish, Old (to 900)",
    sgn: "Sign Languages",
    shn: "Shan",
    si: "Sinhala,Sinhalese",
    sid: "Sidamo",
    sio: "Siouan languages",
    sit: "Sino-Tibetan languages",
    sk: "Slovak",
    sl: "Slovenian",
    sla: "Slavic languages",
    sm: "Samoan",
    sma: "Southern Sami",
    smi: "Sami languages",
    smj: "Lule Sami",
    smn: "Inari Sami",
    sms: "Skolt Sami",
    sn: "Shona",
    snk: "Soninke",
    so: "Somali",
    sog: "Sogdian",
    son: "Songhai languages",
    sq: "Albanian",
    sr: "Serbian",
    srn: "Sranan Tongo",
    srr: "Serer",
    ss: "Swati",
    ssa: "Nilo-Saharan languages",
    st: "Sotho, Southern",
    su: "Sundanese",
    suk: "Sukuma",
    sus: "Susu",
    sux: "Sumerian",
    sv: "Swedish",
    sw: "Swahili",
    syc: "Classical Syriac",
    syr: "Syriac",
    ta: "Tamil",
    tai: "Tai languages",
    te: "Telugu",
    tem: "Timne",
    ter: "Tereno",
    tet: "Tetum",
    tg: "Tajik",
    th: "Thai",
    ti: "Tigrinya",
    tig: "Tigre",
    tiv: "Tiv",
    tk: "Turkmen",
    tkl: "Tokelau",
    tl: "Tagalog",
    tlh: "Klingon,tlhIngan-Hol",
    tli: "Tlingit",
    tmh: "Tamashek",
    tn: "Tswana",
    to: "Tonga (Tonga Islands)",
    tog: "Tonga (Nyasa)",
    tpi: "Tok Pisin",
    tr: "Turkish",
    ts: "Tsonga",
    tsi: "Tsimshian",
    tt: "Tatar",
    tum: "Tumbuka",
    tup: "Tupi languages",
    tut: "Altaic languages",
    tvl: "Tuvalu",
    tw: "Twi",
    ty: "Tahitian",
    tyv: "Tuvinian",
    udm: "Udmurt",
    ug: "Uighur,Uyghur",
    uga: "Ugaritic",
    uk: "Ukrainian",
    umb: "Umbundu",
    und: "Undetermined",
    ur: "Urdu",
    uz: "Uzbek",
    vai: "Vai",
    ve: "Venda",
    vi: "Vietnamese",
    vo: "Volapük",
    vot: "Votic",
    wa: "Walloon",
    wak: "Wakashan languages",
    wal: "Wolaitta,Wolaytta",
    war: "Waray",
    was: "Washo",
    wen: "Sorbian languages",
    wo: "Wolof",
    xal: "Kalmyk,Oirat",
    xh: "Xhosa",
    yao: "Yao",
    yap: "Yapese",
    yi: "Yiddish",
    yo: "Yoruba",
    ypk: "Yupik languages",
    za: "Chuang,Zhuang",
    zap: "Zapotec",
    zbl: "Bliss,Blissymbolics,Blissymbols",
    zen: "Zenaga",
    zgh: "Standard Moroccan Tamazight",
    zh: "Chinese",
    znd: "Zande languages",
    zu: "Zulu",
    zun: "Zuni",
    zxx: "No linguistic content,Not applicable",
    zza: "Dimili,Dimli,Kirdki,Kirmanjki,Zaza,Zazaki",
  },
  countryCodes: {
    AD: "Andorra",
    AE: "United Arab Emirates (the)",
    AF: "Afghanistan",
    AG: "Antigua and Barbuda",
    AI: "Anguilla",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentina",
    AS: "American Samoa",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "Åland Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burma,Burundi",
    BJ: "Benin",
    BL: "Saba,Sahrawi Arab Democratic Republic,Saint Barthélemy",
    BM: "Bermuda",
    BN: "British Virgin Islands,Brunei Darussalam",
    BO: "Bolivia (Plurinational State of)",
    BQ: "Bonaire",
    BR: "Brazil",
    BS: "Bahamas (the)",
    BT: "Bhutan",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Canada",
    CC: "Cocos (Keeling) Islands (the)",
    CD: "Congo (the Democratic Republic of the)",
    CF: "Central African Republic (the)",
    CG: "Congo (the)",
    CH: "Switzerland",
    CI: "Côte d'Ivoire",
    CK: "Cook Islands (the)",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Cabo Verde",
    CW: "Curaçao",
    CX: "China, The Republic of,Christmas Island",
    CY: "Cyprus",
    CZ: "Czechia",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Democratic People's Republic of Korea,Democratic Republic of the Congo,Denmark",
    DM: "Dominica",
    DO: "Dominican Republic (the)",
    DZ: "Algeria",
    EC: "East Timor,Ecuador",
    EE: "Estonia",
    EG: "Egypt",
    EH: "Western Sahara",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands (the) [Malvinas]",
    FM: "Micronesia (Federated States of)",
    FO: "Faroe Islands (the)",
    FR: "France",
    GA: "Gabon",
    GB: "United Kingdom of Great Britain and Northern Ireland (the)",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Greenland",
    GM: "Gambia (the)",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Great Britain,Greece",
    GS: "South Georgia and the South Sandwich Islands",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hong Kong",
    HM: "Heard Island and McDonald Islands",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territory (the)",
    IQ: "Iraq",
    IR: "Iran (Islamic Republic of)",
    IS: "Iceland",
    IT: "Italy",
    JE: "Jersey",
    JM: "Ivory Coast,Jamaica",
    JO: "Jordan",
    JP: "Jan Mayen,Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KI: "Kiribati",
    KM: "Comoros (the)",
    KN: "Saint Kitts and Nevis",
    KP: "Korea (the Democratic People's Republic of)",
    KR: "Korea (the Republic of)",
    KW: "Kuwait",
    KY: "Cape Verde,Caribbean Netherlands,Cayman Islands (the)",
    KZ: "Kazakhstan",
    LA: "Lao People's Democratic Republic (the)",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    MD: "Moldova (the Republic of)",
    ME: "Montenegro",
    MF: "Saint Martin (French part)",
    MG: "Madagascar",
    MH: "Marshall Islands (the)",
    MK: "North Macedonia",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolia",
    MO: "Macao",
    MP: "North Korea,Northern Mariana Islands (the)",
    MQ: "Martinique",
    MR: "Mauritania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger (the)",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands, Kingdom of the",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "People's Republic of China,Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PH: "Philippines (the)",
    PK: "Pakistan",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    PN: "Pitcairn",
    PR: "Puerto Rico",
    PS: "Palestine, State of",
    PT: "Portugal",
    PW: "Palau",
    PY: "Paraguay",
    QA: "Qatar",
    RE: "Republic of China,Republic of Korea,Republic of the Congo,Réunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russian Federation (the)",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SB: "Solomon Islands",
    SC: "Seychelles",
    SD: "Sudan (the)",
    SE: "Sweden",
    SG: "Singapore",
    SH: "Saint Helena",
    SI: "Slovenia",
    SJ: "Svalbard",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Suriname",
    SS: "South Korea,South Sudan",
    ST: "Sao Tome and Principe",
    SV: "El Salvador",
    SX: "Sint Eustatius,Sint Maarten (Dutch part)",
    SY: "Syrian Arab Republic (the)",
    SZ: "Eswatini",
    TC: "Turks and Caicos Islands (the)",
    TD: "Chad",
    TF: "French Southern Territories (the)",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tajikistan",
    TK: "Tokelau",
    TL: "Timor-Leste",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TO: "Tonga",
    TR: "Türkiye",
    TT: "Trinidad and Tobago",
    TV: "Tuvalu",
    TW: "Taiwan (Province of China)",
    TZ: "Tanzania, the United Republic of",
    UA: "Ukraine",
    UG: "Uganda",
    UM: "United States Minor Outlying Islands (the)",
    US: "United States of America (the)",
    UY: "United States Virgin Islands,Uruguay",
    UZ: "Uzbekistan",
    VA: "Holy See (the)",
    VC: "Saint Vincent and the Grenadines",
    VE: "Vatican City,Venezuela (Bolivarian Republic of)",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    VN: "Viet Nam",
    VU: "Vanuatu",
    WF: "Wallis and Futuna",
    WS: "Samoa",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
};
