import React from "react";

//bootstrap
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//components
import AppCard from "../components/AppCard";

const Games = () => {
  return (
    <>
      <Container className="text-light">
        <h3>Games!</h3>
      </Container>
      <Container className="bg-dark py-2">
        <h4 className="text-light">HTML</h4>
        <Row>
          <Col>
            <AppCard
              cardTitle="Click Counter"
              cardText="See how fast you can click"
              cardFooter="Last updated 3 mins ago"
              cardLink="/games/clickcounter"
            />
          </Col>
          <Col>
            <AppCard />
          </Col>
          <Col>
            <AppCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Games;
