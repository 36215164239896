import { gql } from "@apollo/client";

export const QUERY_USER = gql`
  query user($id: ID!) {
    user(_id: $id) {
      _id
      name
      email
      createdAt
      updatedAt
      favoriteApps
      comments
      avatar
      theme
      bio
      github
      contactEmail
    }
  }
`;

export const QUERY_ME = gql`
  {
    me {
      _id
      name
      email
      createdAt
      updatedAt
      favoriteApps {
        _id
      }
      comments {
        _id
      }
      role
      avatar
      theme
      bio
      github
      contactEmail
    }
  }
`;

export const APPS = gql`
  query Apps {
    apps {
      _id
      createdAt
      updatedAt
      releaseDate
      newFlag
      version
      versionDate
      name
      category1
      category2
      keywords
      description
      cardDescription
      cardImg
      instructions
      appLocation
      comments {
        _id
        createdAt
        updatedAt
        createdByUser {
          _id
        }
        name
        commentBody
      }
      createdBy
      favoritedByUsers {
        _id
      }
    }
  }
`;

export const APP = gql`
  query app($id: ID!) {
    app(_id: $id) {
      _id
      newFlag
      name
      description
      instructions
      appLocation
      favoritedByUsers {
        _id
      }
      comments {
        _id
        createdAt
        updatedAt
        createdByUser {
          _id
        }
        name
        commentBody
      }
      createdBy
    }
  }
`;

// export const FAVORITES = gql``;
