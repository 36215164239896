//react
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import {
  Calendar2Event,
  Clock,
  InfoCircle,
  FiletypeHtml,
  PlusSquare,
  DashSquare,
  ChevronDoubleUp,
  Braces,
  XSquare,
  Clipboard,
  Display,
} from "react-bootstrap-icons";

//bootstrap
import {
  Container,
  Row,
  Col,
  Accordion,
  Form,
  InputGroup,
  Dropdown,
  Badge,
  Table,
  OverlayTrigger,
  Tooltip,
  Popover,
  Collapse,
} from "react-bootstrap";

import "./DateTimeFormat.css";
import { dateConstructors } from "./DateTimeFormat-data";
import { countryRegionLocales } from "./DateTimeFormat-locales";

const DateTimeFormat = () => {
  const [formData, setFormData] = useState({
    dropdownLocaleRegionCode: !navigator.language
      ? "en"
      : navigator.language.split("-")[0],
    dropdownLocaleCountryCode: !navigator.language
      ? "US"
      : navigator.language.split("-")[1],
    formLocale: !navigator.language ? "en-US" : navigator.language,
    includeDate: true,
    includeTime: true,
    // IIFE - creates the initial string date value for the form using an IIFE
    userFormDate: (() => {
      const newDate = new Date();

      return `${newDate.toLocaleDateString(navigator.language, {
        year: "numeric",
      })}-${newDate.toLocaleDateString(navigator.language, {
        month: "2-digit",
      })}-${newDate.toLocaleDateString(navigator.language, {
        day: "2-digit",
      })}`;
    })(),
    // IIFE - creates the initial string time value for the form using an IIFE
    userFormTime: (() => {
      return `${new Date(Date.now()).toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: "3",
        hourCycle: "h23",
      })}`;
    })(),
    // dateObj: new Date("August 19, 1975 23:15:30"),
    dateFormats: {},
    userBrowserLanguageOptions: {
      ...Intl.DateTimeFormat(navigator.language).resolvedOptions(),
    },
    optionsList: {},
    incompatibleList: {},
    examples: {
      timeStep: 60,
    },
  });

  const [stickyBoxTop, setStickyBoxTop] = useState(0);
  // stickyBox collapsed state
  const [stickyBoxCollapse, setStickyBoxCollapse] = useState(true);

  // gets the navbarheight to set the stickyBox top position. According to documentation, react is smart enough to run after the elements mount.
  // TODO: refactor stickyBox positioning once all features are complete
  useEffect(() => {
    if (document.getElementById("mainNavbar").offsetHeight > 0) {
      // setStickyBoxTop(document.getElementById("mainNavbar").offsetHeight + 0);
      setStickyBoxTop(document.getElementById("mainNavbar").offsetHeight + 0);
    }
  }, []);

  // creates formatted data for all date format constructors
  function getTimeFormats(date) {
    // TODO: refactor to utility function or redux reducer(redux also uses immer)

    const formatData = dateConstructors.reduce(
      (constructorObjects, constructorObject) => ({
        ...constructorObjects,
        [constructorObject.constructorName]: !constructorObject.parameters
          ? constructorObject.dateConstructor(date)
          : constructorObject.parameters.reduce(
              (parameterObjects, parameterObj) => ({
                ...parameterObjects,
                [parameterObj.parameterName]:
                  parameterObj.inputType !== "object"
                    ? constructorObject.dateConstructor(date)
                    : parameterObj.objectProps.reduce(
                        (objectPropsObjects, objectPropObject) => ({
                          ...objectPropsObjects,
                          [objectPropObject.propName]:
                            objectPropObject.inputType !== "list" &&
                            objectPropObject.inputType !== "collapse"
                              ? constructorObject.dateConstructor(date)
                              : objectPropObject.inputValues.reduce(
                                  (valuesObject, propValue) => ({
                                    ...valuesObject,
                                    [propValue]:
                                      // date function that sends properties if they exist, e.g. toLocalString(locales, options)
                                      // START
                                      constructorObject.dateConstructor(
                                        date,
                                        formData.formLocale
                                          ? formData.formLocale
                                          : null,
                                        {
                                          [objectPropObject.propName]:
                                            propValue,
                                        }
                                      ),
                                    // END
                                  }),
                                  {}
                                ),
                        }),
                        {}
                      ),
              }),
              {}
            ),
      }),
      {}
    );

    return formatData;
  }

  // creates new date object, calls getTimeFormats, and updates the formData state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => {
      // starts form and dates with current date
      let dateObj = new Date(
        `${formData.userFormDate} ${formData.userFormTime}`
      );

      // This prevents errors while the user is typing their locale or the locale is invalid.
      try {
        dateObj.toLocaleString(formData.formLocale);
      } catch (error) {
        return;
      }

      // if date/time values are invalid, revert to current date/time.
      if (!dateObj) {
        dateObj = new Date(Date.now());
      }

      const updatedFormats = getTimeFormats(dateObj);

      setFormData({
        ...formData,
        dateFormats: updatedFormats,
        date: dateObj.toISOString(),
      });
    },
    // eslint is suggesting to add formData and getTimeFormats to depedency array. The intent is not to have the useEffect for updates any key in formData, just the form's date, time and locale. getTimeFormats is a function that does not update date, time, or locale for the form.
    // disabling eslint's error for the dependencies.

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.userFormDate, formData.userFormTime, formData.formLocale]
  );

  const handleFormChange = (event) => {
    const name = event.target.name;
    const type = event.target.type;

    if (type === "checkbox") {
      const value = event.target.checked;
      // const updatedOptionsList = { ...formData.optionsList };
      // const updatedIncompatibleList = { ...formData.incompatibleList };

      // Clearing option list to remove invalid options e.g. date options added before user changes the switch to remove date and keep time. Removing options also needs to update the incompatibleList.
      setFormData({
        ...formData,
        [name]: value,
        optionsList: {},
        incompatibleList: {},
      });
    } else {
      const value = event.target.value;

      if (event.target.parameter === "locales") {
        const updatedLocales = [...formData.formLocale.split("-")];

        if (name === "dropdownLocaleRegionCode") {
          updatedLocales.splice(0, 1, value);
        } else {
          updatedLocales.splice(1, 1, value);
        }

        setFormData({
          ...formData,
          [name]: value,
          formLocale: updatedLocales.join("-"),
        });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    }
  };

  // The sticky functionality was causing the box to jump when accordions were closing and opening. This will trigger the visibility to show and hide the sticky box before the accordions open and close.
  const stickyBoxStick = (positionValue, stickyBoxId) => {
    document.getElementById(stickyBoxId).style.position = positionValue;
  };

  //object to string formatter
  const objectFormatter = (obj, indentCount, factor) => {
    return Object.entries(obj).map(([key, value]) => {
      let dateConstructName;
      if (formData.includeDate === formData.includeTime) {
        dateConstructName = "toLocaleString";
      } else if (formData.includeDate) {
        dateConstructName = "toLocaleDateString";
      } else {
        dateConstructName = "toLocaleTimeString";
      }

      const incompatible = dateConstructors
        .find((c) => c.constructorName === dateConstructName)
        ?.parameters.find((p) => p.parameterName === "options")
        ?.objectProps.find((o) => o.propName === key)?.incompatibleProps;

      if (typeof value === "object" && value !== null) {
        return (
          <div key={key}>
            <span>
              {` `.repeat(indentCount * factor)}
              <DashSquare
                onClick={(e1) =>
                  handleUpdateOptions(e1, key, "remove", incompatible)
                }
              />
              {key}:{" "}
            </span>
            {objectFormatter(value, indentCount + 1, factor)}
          </div>
        );
      }

      return (
        <div key={key}>
          <span>
            {` `.repeat(indentCount * factor)}
            <DashSquare
              onClick={(e1) =>
                handleUpdateOptions(e1, key, "remove", incompatible)
              }
            />
            {key}: {JSON.stringify(value)}
            {`,`}
          </span>
        </div>
      );
    });
  };

  const handleUpdateOptions = (event, option, action, incompatible) => {
    if (action === "add") {
      if (Object.keys(option)[0] in formData.optionsList) {
        setFormData({
          ...formData,
          optionsList: { ...formData.optionsList, ...option },
        });
      } else {
        const newIncompatibleList = incompatible.reduce(
          (updatedIncompatibleList, key) => {
            updatedIncompatibleList.hasOwnProperty(key)
              ? (updatedIncompatibleList[key] += 1)
              : (updatedIncompatibleList[key] = 1);
            return updatedIncompatibleList;
          },
          {
            ...formData.incompatibleList,
          }
        );

        setFormData({
          ...formData,
          optionsList: { ...formData.optionsList, ...option },
          incompatibleList: newIncompatibleList,
        });
      }
    }

    if (action === "remove") {
      const newIncompatibleList = incompatible.reduce(
        (updatedIncompatibleList, key) => {
          updatedIncompatibleList[key] === 1
            ? delete updatedIncompatibleList[key]
            : (updatedIncompatibleList[key] -= 1);
          return updatedIncompatibleList;
        },
        {
          ...formData.incompatibleList,
        }
      );

      const newOptionList = { ...formData.optionsList };

      delete newOptionList[option];

      setFormData({
        ...formData,
        optionsList: newOptionList,
        incompatibleList: newIncompatibleList,
      });
    }
  };

  // console.log(formData);

  return (
    <>
      {/* Page Header */}
      <Container className="text-light pb-2">
        <h3>DateTimeFormat</h3>
        <p>Visual tool for date & time formatting</p>
      </Container>
      {/* Accordian - DateTime Settings */}
      <Container className="text-light pb-2">
        <Accordion defaultActiveKey="0" alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="fs-6 fw-bold">DateTime Settings</div>
            </Accordion.Header>
            <Accordion.Body className="px-3 pt-2">
              <Form>
                <Row>
                  <Col xs={12} sm="auto" md="auto" lg="auto" xl="auto">
                    <Form.Check
                      className="form-checkbox mb-2 mb-sm-4"
                      checked={formData.includeDate}
                      label="Date"
                      name="includeDate"
                      type="switch"
                      id={`includeDate-checkbox`}
                      onChange={handleFormChange}
                    />
                    <Form.Check
                      className="form-checkbox mb-2 mb-sm-4"
                      checked={formData.includeTime}
                      label="Time"
                      name="includeTime"
                      type="switch"
                      id={`includeTime-checkbox`}
                      onChange={handleFormChange}
                    />
                  </Col>
                  <Col xs={12} sm="auto" md="auto" lg="auto" xl="auto">
                    <Row>
                      {formData.includeDate && (
                        <Col className="d-flex align-items-center pe-1">
                          <Form.Control
                            className="mb-2"
                            size="sm"
                            type="date"
                            name="userFormDate"
                            onChange={handleFormChange}
                            value={formData.userFormDate}
                            // unsupported browsers revert to text input and utiize the pattern attribute.
                            pattern="\d{4}-\d{2}-\d{2}"
                          />
                          <span className="mb-2 mx-2">
                            <OverlayTrigger
                              trigger="click"
                              placement="auto"
                              rootClose="true"
                              overlay={
                                <Popover id="popover-date-input">
                                  <Popover.Header as="h3">
                                    <FiletypeHtml className="me-1" />
                                    Behind the Scenes
                                  </Popover.Header>
                                  <Popover.Body>
                                    <p>{`The input 'date' type stores the value in the YYYY-MM-DD format.`}</p>

                                    <p>
                                      {`The current form date value is:`}
                                      <br /> {formData.userFormDate}
                                    </p>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {/* <Button> */}
                              <InfoCircle className="info-button" />
                              {/* </Button> */}
                            </OverlayTrigger>
                          </span>
                        </Col>
                      )}
                      {formData.includeTime && (
                        <Col className="d-flex align-items-center pe-1">
                          <Form.Control
                            className="mb-2"
                            size="sm"
                            type="time"
                            step=".001"
                            name="userFormTime"
                            onChange={handleFormChange}
                            value={formData.userFormTime}
                          />
                          <span className="mb-2 mx-2">
                            <OverlayTrigger
                              trigger="click"
                              placement="auto"
                              rootClose="true"
                              overlay={
                                <Popover id="popover-date-input">
                                  <Popover.Header as="h3">
                                    <FiletypeHtml className="me-1" />
                                    Behind the Scenes
                                  </Popover.Header>
                                  <Popover.Body>
                                    <p>{`The value of the <input> type='time' is in a 24 hour format as hh:mm, or hh:mm:ss, or hh:mm:ss.sss.`}</p>
                                    <p>
                                      {`The 'step' attribute scale is in seconds and defaults to 60. The format and input box changes to allow seconds or milliseconds by reducing the step value.`}
                                    </p>
                                    <div className="bg-secondary text-white p-1 rounded">
                                      {`Try step 60, 1, and .001 to see the input element change below: `}
                                      <Form.Group as={Row}>
                                        <Form.Label column xs={3}>
                                          Step:
                                        </Form.Label>
                                        <Col xs="auto">
                                          <Form.Control
                                            size="sm"
                                            type="text"
                                            value={formData.examples.timeStep}
                                            style={{
                                              width: "5em",
                                              display: "inherit",
                                            }}
                                            onChange={(e1) =>
                                              setFormData({
                                                ...formData,
                                                examples: {
                                                  ...formData.examples,
                                                  timeStep: e1.target.value,
                                                },
                                              })
                                            }
                                          />
                                        </Col>
                                      </Form.Group>
                                      <Form.Group as={Row}>
                                        <Form.Label column xs={3}>
                                          Time:
                                        </Form.Label>
                                        <Col xs="auto">
                                          <Form.Control
                                            size="sm"
                                            type="time"
                                            step={formData.examples.timeStep}
                                          />
                                        </Col>
                                      </Form.Group>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {/* <Button> */}
                              <InfoCircle className="info-button" />
                              {/* </Button> */}
                            </OverlayTrigger>
                          </span>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  {/* locales row */}
                  <Col xs={12} sm="auto" md="auto" lg="auto" xl="auto">
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formLocale"
                    >
                      <Form.Label column sm="2" className="pt-1">
                        locales:
                      </Form.Label>
                      <Col sm="10" className="d-flex align-items-center pe-1">
                        <InputGroup size="sm" className="mb-2">
                          <Dropdown
                            onSelect={(e1, e2) => {
                              return handleFormChange({
                                target: {
                                  name: "dropdownLocaleRegionCode",
                                  value: e1,
                                  type: "dropdown",
                                  parameter: "locales",
                                },
                              });
                            }}
                          >
                            <Dropdown.Toggle
                              variant="dark"
                              id="dropdownRegionCodes"
                              className="overflow-auto input-group-border"
                            >
                              {formData.dropdownLocaleRegionCode}
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="dark">
                              <Dropdown.Item
                                name={formData.dropdownLocaleRegionCode}
                                disabled
                              >
                                {formData.dropdownLocaleRegionCode}
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              {Object.keys(
                                countryRegionLocales.regionCodes
                              ).map((rc) => (
                                <Dropdown.Item
                                  key={`dd-rc-${rc}`}
                                  eventKey={rc}
                                  name="dropdownLocaleRegionCode"
                                >
                                  {rc}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <Dropdown
                            onSelect={(e1, e2) => {
                              return handleFormChange({
                                target: {
                                  name: "dropdownLocaleCountryCode",
                                  value: e1,
                                  type: "dropdown",
                                  parameter: "locales",
                                },
                              });
                            }}
                          >
                            <Dropdown.Toggle
                              variant="dark"
                              id="dropdownLocaleCountryCode"
                              className="overflow-auto input-group-border"
                            >
                              {formData.dropdownLocaleCountryCode}
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="dark">
                              <Dropdown.Item
                                name={formData.dropdownLocaleCountryCode}
                                disabled
                              >
                                {formData.dropdownLocaleCountryCode}
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              {Object.keys(
                                countryRegionLocales.countryCodes
                              ).map((cc) => (
                                <Dropdown.Item
                                  key={`dd-cc-${cc}`}
                                  eventKey={cc}
                                  name={cc}
                                >
                                  {cc}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <Form.Control
                            className="input-group-border"
                            name="formLocale"
                            value={formData.formLocale}
                            onChange={handleFormChange}
                          />
                        </InputGroup>
                        <span className="mb-2 mx-2">
                          <OverlayTrigger
                            trigger="click"
                            placement="auto"
                            rootClose="true"
                            overlay={
                              <Popover id="popover-locale-input">
                                <Popover.Header as="h3">
                                  <FiletypeHtml className="me-1" />
                                  Behind the Scenes
                                </Popover.Header>
                                <Popover.Body>
                                  <p>{`The default value, if available, is the user's locale based on browser navigator.language value(s)`}</p>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            {/* <Button> */}
                            <InfoCircle className="info-button" />
                            {/* </Button> */}
                          </OverlayTrigger>
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <Container className="text-light pb-2">
        <Accordion defaultActiveKey="1" alwaysOpen className="pb-2">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="fs-6 fw-bold">
                Method 1 -{" "}
                {!formData.includeDate && formData.includeTime
                  ? "toLocaleTimeString()"
                  : formData.includeDate && !formData.includeTime
                  ? "toLocaleDateString()"
                  : "toLocaleString()"}
              </div>
            </Accordion.Header>
            <Accordion.Body
              className="px-3 pt-2"
              onExit={() => {
                return stickyBoxStick("static", "stickyBox-method1");
              }}
              onEntered={() => {
                return stickyBoxStick("sticky", "stickyBox-method1");
              }}
            >
              <div
                className="sticky-box"
                style={{
                  top: `${stickyBoxTop}px`,
                }}
                id={`stickyBox-method1`}
              >
                <div className="position-relative date-box text-light mb-1 p-1 border rounded">
                  {Object.keys(formData.optionsList).length ? (
                    <div
                      className="collapse-button position-absolute bottom-0 end-0 bg-dark rounded-1 px-1 m-1"
                      onClick={() => setStickyBoxCollapse(!stickyBoxCollapse)}
                    >
                      <ChevronDoubleUp
                        className={
                          stickyBoxCollapse
                            ? "arrow-flip-down"
                            : "arrow-flip-up"
                        }
                      />
                    </div>
                  ) : null}
                  <div
                    className={`position-relative${
                      !stickyBoxCollapse ? " collapsed" : ""
                    }`}
                  >
                    <Row>
                      <Col xs="auto" md="auto" className="my-1">
                        {/* if form toggle date and time are both true or both false then display both*/}
                        {formData.includeDate === formData.includeTime ||
                        formData.includeDate ? (
                          <span className="me-2 text-nowrap">
                            <Calendar2Event className="me-1" />
                            <Badge
                              className="fontCode"
                              bg="secondary"
                            >{`${formData.userFormDate}`}</Badge>
                          </span>
                        ) : null}
                        {formData.includeDate === formData.includeTime ||
                        formData.includeTime ? (
                          <span className="text-nowrap mb-1">
                            <Clock className="me-1" />
                            <Badge
                              className="fontCode"
                              bg="secondary"
                            >{`${formData.userFormTime}`}</Badge>
                          </span>
                        ) : null}
                      </Col>
                      {Object.keys(formData.optionsList).length ? (
                        <Col
                          xs="auto"
                          md="auto"
                          className="my-1 d-flex align-items-start"
                        >
                          <span className="icon-back me-1 bg-dark text-white">
                            <Display />
                          </span>
                          <span className="fontCode me-4">
                            <Badge
                              className="fontCode text-wrap text-start"
                              bg="secondary"
                            >
                              {!formData.includeDate && formData.includeTime
                                ? new Date(
                                    `${formData.userFormDate} ${formData.userFormTime}`
                                  ).toLocaleTimeString(
                                    formData.formLocale,
                                    formData.optionsList
                                  )
                                : formData.includeDate && !formData.includeTime
                                ? new Date(
                                    `${formData.userFormDate} ${formData.userFormTime}`
                                  ).toLocaleDateString(
                                    formData.formLocale,
                                    formData.optionsList
                                  )
                                : new Date(
                                    `${formData.userFormDate} ${formData.userFormTime}`
                                  ).toLocaleString(
                                    formData.formLocale,
                                    formData.optionsList
                                  )}
                            </Badge>
                          </span>
                        </Col>
                      ) : null}

                      {Object.keys(formData.optionsList).length ? (
                        <Collapse in={stickyBoxCollapse}>
                          <Col
                            id="collapse-method1"
                            xs={12}
                            md="auto"
                            className="mt-1 mb-3"
                          >
                            <div className="d-flex align-items-start">
                              <span className="icon-back me-1 bg-dark text-white">
                                <Braces />
                              </span>
                              <span className="d-flex align-items-start flex-wrap">
                                <span className="fontCode flex-nowrap mb-1">
                                  {`${
                                    !formData.includeDate &&
                                    formData.includeTime
                                      ? "toLocaleTimeString("
                                      : formData.includeDate &&
                                        !formData.includeTime
                                      ? "toLocaleDateString("
                                      : "toLocaleString("
                                  }`}
                                  <Badge
                                    className="fontCode"
                                    bg="secondary"
                                  >{`${formData.formLocale}`}</Badge>
                                  {`, `}
                                </span>
                                <span className="mb-1">
                                  <Badge
                                    className="fontCode objectCode"
                                    bg="secondary"
                                  >
                                    <div>
                                      <pre>
                                        <div>{`{`}</div>
                                        {objectFormatter(
                                          formData.optionsList,
                                          1,
                                          2
                                        )}
                                        {`}`}
                                      </pre>
                                    </div>
                                  </Badge>
                                </span>
                                <span className="text-nowrap">
                                  <pre className="d-inline me-1">{` )`}</pre>
                                  <span className="d-inline icon-button rounded-1 me-1">
                                    <Clipboard
                                      style={{ marginBottom: "0.1em" }}
                                      onClick={(event) =>
                                        navigator.clipboard.writeText(
                                          `${
                                            !formData.includeDate &&
                                            formData.includeTime
                                              ? ".toLocaleTimeString("
                                              : formData.includeDate &&
                                                !formData.includeTime
                                              ? ".toLocaleDateString("
                                              : ".toLocaleString("
                                          }${
                                            formData.formLocale
                                          }, {${Object.entries(
                                            formData.optionsList
                                          ).map((e1) => {
                                            return `${` `}${e1[0]}: "${e1[1]}"`;
                                          })} } )`
                                        )
                                      }
                                    />
                                  </span>
                                </span>
                              </span>
                            </div>
                          </Col>
                        </Collapse>
                      ) : null}
                    </Row>
                  </div>
                </div>
              </div>

              {dateConstructors
                .filter(
                  (c) =>
                    c.constructorName ===
                    (!formData.includeDate && formData.includeTime
                      ? "toLocaleTimeString"
                      : formData.includeDate && !formData.includeTime
                      ? "toLocaleDateString"
                      : "toLocaleString")
                )
                .map((constructorEntry) => (
                  // Will only have 1 result based on filter
                  <div key={constructorEntry.constructorName}>
                    {/* accordian sub-header */}
                    {constructorEntry.parameters.map((p) => (
                      <div
                        key={`${constructorEntry.constructorName}-${p.parameterName}`}
                        className="bg-dark border rounded p-2 mb-1"
                      >
                        {p.parameterName === "default" && (
                          <div>
                            <div className="mb-2 fw-bold">{p.format}</div>

                            <Table variant="dark" size="sm" hover striped>
                              <thead>
                                <tr>
                                  <th className="d-flex align-items-center">
                                    <span className="pe-2">Output</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      formData.dateFormats[
                                        constructorEntry.constructorName
                                      ][p.parameterName]
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        )}

                        {p.parameterName === "locales" && (
                          <div>
                            <div className="mb-2 fw-bold">
                              {p.format.split("locales")[0]}
                              <Badge className="fontCodePill" bg="secondary">
                                locales
                              </Badge>
                              {p.format.split("locales")[1]}
                            </div>

                            {/* locales row */}
                            <div className="fw-bold border-bottom border-secondary border-opacity-50 p-1">
                              <h5>
                                <Badge className="fontCodePill" bg="secondary">
                                  locales
                                </Badge>
                                {` (string)`}
                              </h5>
                            </div>
                            <div className="mx-1 mb-3">
                              <Table variant="dark" size="sm" hover striped>
                                <thead>
                                  <tr>
                                    <th>Value</th>
                                    <th className="d-flex align-items-center">
                                      <span className="pe-2">Output</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {`${formData.dropdownLocaleRegionCode}-${formData.dropdownLocaleCountryCode}`}
                                    </td>
                                    <td>
                                      {
                                        formData.dateFormats[
                                          constructorEntry.constructorName
                                        ]["locales"]
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        )}

                        {p.parameterName === "options" && (
                          <div>
                            {/* sub-header row */}
                            <div className="mb-2 fw-bold">
                              {p.format.split(/(locales|options)/)[0]}
                              <Badge className="fontCodePill" bg="secondary">
                                locales
                              </Badge>
                              {p.format.split(/(locales|options)/)[2]}
                              <Badge className="fontCodePill" bg="secondary">
                                options
                              </Badge>
                              {p.format.split(/(locales|options)/)[4]}
                            </div>

                            {/* locales row */}
                            <div className="fw-bold border-bottom border-secondary border-opacity-50 p-1">
                              <h5>
                                <Badge className="fontCodePill" bg="secondary">
                                  locales
                                </Badge>
                                {` (string)`}
                              </h5>
                            </div>
                            <div className="mx-1 mb-3">
                              <Table variant="dark" size="sm" hover striped>
                                <thead>
                                  <tr>
                                    <th>Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {`${formData.dropdownLocaleRegionCode}-${formData.dropdownLocaleCountryCode}`}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>

                            {/* options row */}
                            <div className="fw-bold border-bottom border-secondary border-opacity-50 p-1">
                              <h5>
                                <Badge className="fontCodePill" bg="secondary">
                                  options
                                </Badge>
                                {` (object)`}
                              </h5>
                              {/* <div>{`{ option: "value" }`}</div> */}
                            </div>
                            <div className="mx-1 mb-3">
                              {p.objectProps
                                ? p.objectProps.map((o) => (
                                    <Row
                                      key={`object-${constructorEntry.constructorName}-${p.parameterName}-${o.propName}`}
                                      className="m-0 border-bottom border-secondary border-opacity-50"
                                    >
                                      <Col
                                        xs={12}
                                        sm={4}
                                        lg={3}
                                        className="p-1"
                                      >
                                        <div className="p-1 fst-italic d-flex align-items-center">
                                          <span className="pe-2">
                                            {o.propName}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={12}
                                        sm={8}
                                        lg="auto"
                                        className="p-1"
                                      >
                                        {o.inputType === "list" ? (
                                          <Table
                                            // className="fontCode"
                                            variant="dark"
                                            size="sm"
                                            hover
                                            striped
                                          >
                                            <thead>
                                              <tr>
                                                <th>Value</th>
                                                <th className="ps-lg-1 pe-lg-3">
                                                  Output
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {o.inputValues.map((i) => (
                                                <tr
                                                  key={`propertyValue-${constructorEntry.constructorName}-${p.parameterName}-${o.propName}-${i}`}
                                                >
                                                  <td>
                                                    <div className="d-flex align-items-center">
                                                      {o.propName in
                                                      formData.incompatibleList ? (
                                                        <span className="incompatible d-flex align-items-center">
                                                          <OverlayTrigger
                                                            key={`incompatibleOverlayTrigger-${o.propName}`}
                                                            placement="right"
                                                            overlay={
                                                              <Tooltip
                                                                id={`incompatibleTooltip-${o.propName}`}
                                                              >
                                                                <div className="text-start">
                                                                  <div>
                                                                    {`The `}
                                                                    <span className="fw-bold text-decoration-underline">
                                                                      {
                                                                        o.propName
                                                                      }
                                                                    </span>
                                                                    {` option is incompatible with:`}
                                                                  </div>
                                                                  {o.incompatibleProps.map(
                                                                    (ip) => (
                                                                      <div
                                                                        key={`incompatible-${o.propName}-${ip}`}
                                                                      >
                                                                        {ip}
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <XSquare className="me-1" />
                                                          </OverlayTrigger>
                                                        </span>
                                                      ) : (
                                                        <span className="add-button d-flex align-items-center">
                                                          {formData.optionsList[
                                                            o.propName
                                                          ] === i ? (
                                                            <DashSquare
                                                              name="addOption"
                                                              className="me-1"
                                                              onClick={(e1) =>
                                                                handleUpdateOptions(
                                                                  e1,
                                                                  o.propName,
                                                                  "remove",
                                                                  o.incompatibleProps
                                                                )
                                                              }
                                                              style={{
                                                                strokeWidth:
                                                                  "0.25px",
                                                                stroke:
                                                                  "rgba(248, 249, 250, 0.75)",
                                                              }}
                                                            />
                                                          ) : (
                                                            <PlusSquare
                                                              name="addOption"
                                                              className="me-1"
                                                              onClick={(e1) =>
                                                                handleUpdateOptions(
                                                                  e1,
                                                                  {
                                                                    [o.propName]:
                                                                      i,
                                                                  },
                                                                  "add",
                                                                  o.incompatibleProps
                                                                )
                                                              }
                                                            />
                                                          )}
                                                        </span>
                                                      )}
                                                      {i}
                                                    </div>
                                                  </td>
                                                  <td className="ps-lg-1 pe-lg-3">
                                                    {Object.keys(
                                                      formData.dateFormats
                                                    ).length
                                                      ? formData.dateFormats[
                                                          constructorEntry
                                                            .constructorName
                                                        ][p.parameterName][
                                                          o.propName
                                                        ][i]
                                                      : ""}
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </Table>
                                        ) : (
                                          ""
                                        )}
                                        {o.inputType === "collapse" ? (
                                          <Accordion
                                            className="option-accordion"
                                            flush
                                          >
                                            <Accordion.Item
                                              eventKey="0"
                                              className="text-break"
                                            >
                                              <Accordion.Header as="div">
                                                <Table
                                                  // className="fontCode"
                                                  variant="dark"
                                                  size="sm"
                                                  hover
                                                  striped
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>Value</th>
                                                      <th className="ps-lg-1 pe-lg-3">
                                                        Output
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {Object.keys(
                                                      formData.dateFormats
                                                    ).length ? (
                                                      <tr>
                                                        <td>
                                                          {
                                                            formData
                                                              .userBrowserLanguageOptions[
                                                              o.propName
                                                            ]
                                                          }
                                                        </td>
                                                        <td className="ps-lg-1 pe-lg-3">
                                                          {
                                                            formData
                                                              .dateFormats[
                                                              constructorEntry
                                                                .constructorName
                                                            ][p.parameterName][
                                                              o.propName
                                                            ][
                                                              formData
                                                                .userBrowserLanguageOptions[
                                                                o.propName
                                                              ]
                                                            ]
                                                          }
                                                        </td>
                                                      </tr>
                                                    ) : (
                                                      <tr>
                                                        <td>""</td>
                                                        <td>""</td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <Table
                                                  variant="dark"
                                                  size="sm"
                                                  hover
                                                  striped
                                                >
                                                  <tbody>
                                                    <tr></tr>
                                                    {o.inputValues.map((i) => (
                                                      <tr
                                                        key={`${constructorEntry.constructorName}-${p.parameterName}-${o.propName}-${i}`}
                                                      >
                                                        <td>
                                                          <div className="d-flex align-items-center">
                                                            {o.propName in
                                                            formData.incompatibleList ? (
                                                              <span className="incompatible d-flex align-items-center">
                                                                <OverlayTrigger
                                                                  key={`incompatibleOverlayTrigger-${o.propName}`}
                                                                  placement="right"
                                                                  overlay={
                                                                    <Tooltip
                                                                      id={`incompatibleTooltip-${o.propName}`}
                                                                    >
                                                                      <div className="text-start">
                                                                        <div>
                                                                          {`The `}
                                                                          <span className="fw-bold text-decoration-underline">
                                                                            {
                                                                              o.propName
                                                                            }
                                                                          </span>
                                                                          {` option is incompatible with:`}
                                                                        </div>
                                                                        {o.incompatibleProps.map(
                                                                          (
                                                                            ip
                                                                          ) => (
                                                                            <div
                                                                              key={`incompatible-${o.propName}-${ip}`}
                                                                            >
                                                                              {
                                                                                ip
                                                                              }
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    </Tooltip>
                                                                  }
                                                                >
                                                                  <XSquare className="me-1" />
                                                                </OverlayTrigger>
                                                              </span>
                                                            ) : (
                                                              <span className="add-button d-flex align-items-center">
                                                                {formData
                                                                  .optionsList[
                                                                  o.propName
                                                                ] === i ? (
                                                                  <DashSquare
                                                                    name="addOption"
                                                                    className="me-1"
                                                                    onClick={(
                                                                      e1
                                                                    ) =>
                                                                      handleUpdateOptions(
                                                                        e1,
                                                                        o.propName,
                                                                        "remove",
                                                                        o.incompatibleProps
                                                                      )
                                                                    }
                                                                    style={{
                                                                      strokeWidth:
                                                                        "0.25px",
                                                                      stroke:
                                                                        "rgba(248, 249, 250, 0.75)",
                                                                    }}
                                                                  />
                                                                ) : (
                                                                  <PlusSquare
                                                                    name="addOption"
                                                                    className="me-1"
                                                                    onClick={(
                                                                      e1
                                                                    ) =>
                                                                      handleUpdateOptions(
                                                                        e1,
                                                                        {
                                                                          [o.propName]:
                                                                            i,
                                                                        },
                                                                        "add",
                                                                        o.incompatibleProps
                                                                      )
                                                                    }
                                                                  />
                                                                )}
                                                              </span>
                                                            )}
                                                            {i}
                                                          </div>
                                                        </td>
                                                        <td className="ps-lg-1 pe-lg-3">
                                                          {Object.keys(
                                                            formData.dateFormats
                                                          ).length
                                                            ? formData
                                                                .dateFormats[
                                                                constructorEntry
                                                                  .constructorName
                                                              ][
                                                                p.parameterName
                                                              ][o.propName][i]
                                                            : ""}
                                                        </td>
                                                      </tr>
                                                    ))}
                                                  </tbody>
                                                </Table>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                  ))
                                : ""}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    {/* resources */}
                    {!constructorEntry.resources.length ? null : (
                      <div className="bg-dark border rounded p-2 mb-1 text-break">
                        <div className="mb-2 fw-bold">Resource(s)</div>

                        <ul className="ps-4">
                          {constructorEntry.resources.map(
                            (resource, resourceIndex) => (
                              <li
                                key={`resource-${constructorEntry.constructorName}-${resourceIndex}`}
                                className="mb-1"
                              >
                                <Link
                                  className="link-light"
                                  to={resource.linkUrl}
                                >
                                  {resource.linkText}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="2" alwaysOpen className="pb-2">
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="fs-6 fw-bold">
                Method 2 - Date Constructors by Parts
              </div>
            </Accordion.Header>
            <Accordion.Body
              className="px-3 pt-2"
              onExit={() => {
                return stickyBoxStick("static", "stickyBox-method2");
              }}
              onEntered={() => {
                return stickyBoxStick("sticky", "stickyBox-method2");
              }}
            >
              <div
                className="sticky-box"
                style={{
                  top: `${stickyBoxTop}px`,
                }}
                id={`stickyBox-method2`}
              >
                <div className="position-relative date-box text-light mb-1 p-1 border rounded">
                  <div className={`position-relative`}>
                    <Row>
                      <Col xs="auto" md="auto" className="my-1">
                        {/* if form toggle date and time are both true or both false then display both*/}
                        {formData.includeDate === formData.includeTime ||
                        formData.includeDate ? (
                          <span className="me-2 text-nowrap">
                            <Calendar2Event className="me-1" />
                            <Badge
                              className="fontCode"
                              bg="secondary"
                            >{`${formData.userFormDate}`}</Badge>
                          </span>
                        ) : null}
                        {formData.includeDate === formData.includeTime ||
                        formData.includeTime ? (
                          <span className="text-nowrap mb-1">
                            <Clock className="me-1" />
                            <Badge
                              className="fontCode"
                              bg="secondary"
                            >{`${formData.userFormTime}`}</Badge>
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              {dateConstructors.map((constructorEntry) =>
                [
                  "toLocaleString",
                  "toLocaleDateString",
                  "toLocaleTimeString",
                ].includes(constructorEntry.constructorName) ? null : (
                  <div
                    key={constructorEntry.constructorName}
                    className="bg-dark border rounded p-2 mb-1"
                  >
                    <div className="mb-2">
                      <div className="fw-bold mb-1">
                        {`${constructorEntry.constructorName}()`}
                      </div>
                      {"description" in constructorEntry ? (
                        <p>{constructorEntry.description}</p>
                      ) : null}
                    </div>

                    <Row>
                      <Col md="auto">
                        <Table variant="dark" size="sm" hover striped>
                          <thead>
                            <tr>
                              <th className="ps-md-1 pe-md-3 d-flex align-items-center">
                                <span className="pe-2">Output</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="ps-md-1 pe-md-3 text-break">
                                {/* react considered the toString results as a function. Wrapping in literal removed the log errors. */}
                                {`
                                  ${
                                    formData.dateFormats[
                                      constructorEntry.constructorName
                                    ]
                                  }`}
                                {/* {
                                  formData.dateFormats[
                                    constructorEntry.constructorName
                                  ]
                                } */}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>

                    {/* example row if data exists */}
                    {Object.keys(constructorEntry.example).length ? (
                      <div>
                        <div className="p-1 fw-bold">Example</div>
                        <div className="fontCode">
                          {constructorEntry.example}
                        </div>
                      </div>
                    ) : null}

                    {/* resources */}
                    {!constructorEntry.resources.length ? null : (
                      <div>
                        <div className="p-1 fw-bold">Resource(s)</div>

                        <ul className="ps-4 text-break">
                          {constructorEntry.resources.map(
                            (resource, resourceIndex) => (
                              <li
                                key={`resource-${constructorEntry.constructorName}-${resourceIndex}`}
                                className="mb-1"
                              >
                                <Link
                                  className="link-light"
                                  to={resource.linkUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {resource.linkText}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                )
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default DateTimeFormat;
