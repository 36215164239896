import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// apollo
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// redux
// import { store } from "./store";
// import { Provider as ReduxProvider } from "react-redux";

// Component imports
import Navbar from "./components/Navbar";

//games
import ClickCounter from "./components/games/ClickCounter";

//tools
import SVG from "./components/tools/svg/";
import JSON from "./components/tools/JSON";
import DateTimeFormat from "./components/tools/DateTimeFormat";
import Example from "./components/tools/DateTimeFormat/test";
import PwaTester from "./components/tools/PwaTester";
import ApiStore from "./components/tools/ApiStore";

// Pages imports
import Home from "./pages/Home";
// import Splash from "./pages/Splash";
import Games from "./pages/Games";
import Tools from "./pages/Tools";
import About from "./pages/About";
import EditApps from "./pages/EditApps";

// If user not logged in, displays generic splash page and to login or signup or possibly browse
import Auth from "./utils/auth";

// Redux Store Imports
import { Profile } from "./store/profile/Profile";
import { Apps } from "./store/apps/Apps";

const httpLink = createHttpLink({
  uri: "/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("id_token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const loggedIn = Auth.loggedIn();

function App() {
  return (
    <ApolloProvider client={client}>
      {/* <ReduxProvider store={store}> */}
      <Router>
        <div className="bg-dark min-vh-100">
          <Navbar />
          <Routes>
            {loggedIn ? (
              <Route exact path="/" element={<Home />} />
            ) : (
              // Change back to splash once splash page is created
              // <Route exact path="/" element={<Splash />} />
              <Route exact path="/" element={<Home />} />
            )}
            <Route exact path="/games" element={<Games />} />
            <Route exact path="/tools" element={<Tools />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/tools/svg" element={<SVG />} />
            <Route exact path="/tools/json" element={<JSON />} />
            <Route
              exact
              path="/tools/datetimeformat"
              element={<DateTimeFormat />}
            />
            <Route
              exact
              path="/tools/datetimeformat/example"
              element={<Example />}
            />
            <Route exact path="/tools/pwatester" element={<PwaTester />} />
            <Route exact path="/tools/apistore" element={<ApiStore />} />
            <Route
              exact
              path="/games/clickcounter"
              element={<ClickCounter />}
            />
            <Route exact path="/store/profile" element={<Profile />} />
            <Route exact path="/store/apps" element={<Apps />} />
            <Route exact path="/edit/apps" element={<EditApps />} />

            <Route render={() => <h1 className="display-2">Wrong page!</h1>} />
          </Routes>
        </div>
      </Router>
      {/* </ReduxProvider> */}
    </ApolloProvider>
  );
}

export default App;
