import { Button, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";

//store
import Favorite from "./Favorite";

function AppCard({ appId, cardTitle, cardText, cardFooter, cardLink }) {
  let cardFooterArr = [];

  if (cardFooter) {
    cardFooterArr = cardFooter.split(" at ");
  }

  // console.log("card-appId");
  // console.log(appId);
  return (
    <Card
      id={appId}
      bg="secondary"
      text="light"
      border="light"
      className="appCard mb-2"
      aria-label={cardTitle}
    >
      {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
      <Card.Body>
        <Card.Title>{cardTitle}</Card.Title>
        <Card.Text>{cardText}</Card.Text>
        <Button variant="dark" href={cardLink}>
          Start
        </Button>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col xs={6} className="d-flex">
            {cardFooter ? (
              <div className="d-flex flex-column justify-content-end">
                <div className="text-white-50 cardFooter">
                  Created by {cardFooterArr[1]}
                </div>
                <div className="text-white-50 cardFooter">
                  {cardFooterArr[0]}
                </div>
              </div>
            ) : (
              <div>loading...</div>
            )}
          </Col>
          <Col></Col>
          <Col>
            <Favorite appId={appId} />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

export default AppCard;
