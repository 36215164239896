import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counter/counterSlice";
import profileReducer from "./profile/profileSlice";
import appsReducer from "./apps/appsSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    profile: profileReducer,
    apps: appsReducer,
  },
});
