import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
        name
        email
        createdAt
        updatedAt
        favoriteApps {
          _id
        }
        comments {
          _id
        }
        role
        avatar
        theme
        bio
        github
        contactEmail
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!) {
    addUser(password: $password) {
      token
      user {
        _id
        name
        email
        createdAt
        updatedAt
        role
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($name: String!, $email: String!, $password: String!) {
    addUser(name: $name, email: $email, password: $password) {
      token
      user {
        _id
        name
        email
        createdAt
        updatedAt
        role
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String
    $password: String
    $name: String
    $role: String
    $avatar: String
    $theme: String
    $bio: String
    $github: String
    $contactEmail: String
  ) {
    updateUser(
      _id: $id
      email: $email
      password: $password
      name: $name
      role: $role
      avatar: $avatar
      theme: $theme
      bio: $bio
      github: $github
      contactEmail: $contactEmail
    ) {
      _id
      name
      email
      createdAt
      updatedAt
      favoriteApps {
        _id
      }
      comments {
        _id
      }
      role
      avatar
      theme
      bio
      github
      contactEmail
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser($id: ID!) {
    removeUser(_id: $id) {
      _id
      name
      email
      createdAt
      updatedAt
      role
      avatar
      theme
      bio
      github
      contactEmail
    }
  }
`;

export const ADD_APP = gql`
  mutation AddApp(
    $name: String!
    $category1: String!
    $category2: String!
    $releaseDate: String
    $newFlag: Boolean
    $version: String
    $versionDate: String
    $keywords: [String]
    $description: String!
    $cardDescription: String!
    $cardImg: String
    $instructions: String
    $appLocation: String
    $createdBy: String
  ) {
    addApp(
      name: $name
      category1: $category1
      category2: $category2
      releaseDate: $releaseDate
      newFlag: $newFlag
      version: $version
      versionDate: $versionDate
      keywords: $keywords
      description: $description
      cardDescription: $cardDescription
      cardImg: $cardImg
      instructions: $instructions
      appLocation: $appLocation
      createdBy: $createdBy
    ) {
      _id
      createdAt
      updatedAt
      releaseDate
      newFlag
      version
      versionDate
      name
      category1
      category2
      keywords
      description
      cardDescription
      cardImg
      instructions
      appLocation
      createdBy
    }
  }
`;

export const UPDATE_APP = gql`
  mutation UpdateApp(
    $id: ID!
    $name: String
    $newFlag: Boolean
    $version: String
    $versionDate: String
    $category1: String!
    $category2: String!
    $keywords: [String]
    $description: String!
    $cardDescription: String!
    $cardImg: String
    $instructions: String
    $appLocation: String
    $createdBy: String
    $releaseDate: String
  ) {
    updateApp(
      _id: $id
      name: $name
      newFlag: $newFlag
      version: $version
      versionDate: $versionDate
      category1: $category1
      category2: $category2
      keywords: $keywords
      description: $description
      cardDescription: $cardDescription
      cardImg: $cardImg
      instructions: $instructions
      appLocation: $appLocation
      createdBy: $createdBy
      releaseDate: $releaseDate
    ) {
      _id
      createdAt
      updatedAt
      releaseDate
      newFlag
      version
      versionDate
      name
      category1
      category2
      keywords
      description
      cardDescription
      cardImg
      instructions
      appLocation
      comments {
        _id
      }
      favoritedByUsers {
        _id
      }
      createdBy
      userHighScores {
        name
        score
        date
      }
    }
  }
`;

export const REMOVE_APP = gql`
  mutation RemoveApp($id: ID!) {
    removeApp(_id: $id) {
      _id
      createdAt
      updatedAt
      releaseDate
      newFlag
      version
      versionDate
      name
      category1
      category2
      keywords
      description
      cardDescription
      cardImg
      instructions
      appLocation
      createdBy
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment($appId: ID!, $commentBody: String!, $appName: String) {
    addComment(appId: $appId, commentBody: $commentBody, appName: $appName) {
      _id
      createdAt
      updatedAt
      createdForApp {
        _id
      }
      appName
      createdByUser {
        _id
      }
      name
      commentBody
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: ID!, $commentBody: String!) {
    updateComment(_id: $id, commentBody: $commentBody) {
      _id
      commentBody
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation RemoveComment($id: ID!) {
    removeComment(_id: $id) {
      _id
      createdAt
      updatedAt
      createdForApp {
        _id
      }
      appName
      createdByUser {
        _id
      }
      name
      commentBody
    }
  }
`;

export const ADD_FAVORITE = gql`
  mutation AddFavorite($appId: ID!) {
    addFavorite(appId: $appId) {
      favoriteApps {
        _id
      }
      _id
    }
  }
`;

export const REMOVE_FAVORITE = gql`
  mutation RemoveFavorite($appId: ID!) {
    removeFavorite(appId: $appId) {
      _id
      favoriteApps {
        _id
      }
    }
  }
`;
