import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useQuery } from "@apollo/client";
import { APPS } from "../utils/queries";
import { showApps } from "../store/apps/appsSlice";

//bootstrap
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//components
import AppCard from "../components/AppCard";

//auth
import Auth from "../utils/auth";

const loggedIn = Auth.loggedIn();

const Home = () => {
  const appList = useSelector((state) => state.apps.value);
  const dispatch = useDispatch();

  const { loading, data, error } = useQuery(APPS);

  useEffect(() => {
    if (loading) {
      console.log("loading");
      console.log(loading);
    }

    if (error) {
      console.log("error");
      console.log(error);
    }

    if (data) {
      console.log("apps-data");
      console.log(data.apps);
      dispatch(showApps(data.apps));
    }
  }, [data, loading, error, dispatch]);
  return (
    <>
      {/* Greeting */}
      {loggedIn && (
        <Container>
          <h3 className="text-light">
            Hello {`${Auth.getProfile().data.name}`}
          </h3>
        </Container>
      )}

      {/* Favorites */}
      {loggedIn && (
        <Container className="bg-dark py-2">
          <h4 className="text-light">Favorites</h4>
          <Row>
            <Col>
              <AppCard />
            </Col>
            <Col>
              <AppCard />
            </Col>
            <Col>
              <AppCard />
            </Col>
          </Row>
        </Container>
      )}

      {/* Tools */}
      <Container className="bg-dark py-2">
        <h4 className="text-light">Tools</h4>
        {loading ? (
          <div>loading...</div>
        ) : appList.length ? (
          appList.map((app) =>
            app.category1 === "Tools" ? (
              <AppCard
                key={app._id}
                appId={app._id}
                cardTitle={app.name}
                cardText={app.cardDescription}
                cardFooter={app.createdAt}
                cardLink={app.appLocation}
                className="text-white"
              />
            ) : null
          )
        ) : (
          <div className="text-white">No Apps</div>
        )}
      </Container>

      {/* Games */}
      <Container className="bg-dark py-2">
        <h4 className="text-light">Games</h4>
        {loading ? (
          <div>loading...</div>
        ) : appList.length ? (
          appList.map((app) =>
            app.category1 === "Games" ? (
              <AppCard
                key={app._id}
                appId={app._id}
                cardTitle={app.name}
                cardText={app.cardDescription}
                cardFooter={app.createdAt}
                cardLink={app.appLocation}
                className="text-white"
              />
            ) : null
          )
        ) : (
          <div className="text-white">No Apps</div>
        )}
      </Container>

      {/* Recently Used */}
      {/* {loggedIn && (
        <Container>
          <h3 className="text-light">Recently used...</h3>
          <Row>
            <Col>
              <AppCard />
            </Col>
            <Col>
              <AppCard />
            </Col>
            <Col>
              <AppCard />
            </Col>
            <Col>
              <AppCard />
            </Col>
            <Col>
              <AppCard />
            </Col>
          </Row>
        </Container>
      )} */}
    </>
  );
};

export default Home;
