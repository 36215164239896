import React from "react";
import { Container } from "react-bootstrap";

const About = () => {
  return (
    <>
      <Container className="text-light">
        <h3>About!</h3>
      </Container>
    </>
  );
};

export default About;
