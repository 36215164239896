import { createSlice } from "@reduxjs/toolkit";

export const appsSlice = createSlice({
  name: "apps",
  initialState: {
    value: {
      _id: "",
      createdAt: "",
      updatedAt: "",
      releaseDate: "",
      version: "",
      versionDate: "",
      name: "",
      category1: "",
      category2: "",
      keywords: [],
      description: "",
      cardDescription: "",
      cardImg: "",
      instructions: "",
      appLocation: "",
      // userHighScores: [],
      favoritedByUsers: [],
      comments: [],
      createdBy: "",
    },
  },
  reducers: {
    showApps: (state, action) => {
      const cleanPayload = action.payload.map((app) => {
        let cleanObject = {};

        Object.keys(app).forEach((key) => {
          cleanObject = { ...cleanObject, [key]: !app[key] ? "" : app[key] };
        });

        return { ...state.value, ...cleanObject };
      });
      state.value = cleanPayload;
    },
    addApp: (state, action) => {
      console.log("appSlice-updateApps");
      console.log(state.value);
      state.value = [...state.value, action.payload.data];
    },
    updateApp: (state, action) => {
      console.log("appSlice-action.payload.data");
      console.log(action.payload.data);
      state.value = state.value.map((app, i) =>
        i !== action.payload.index ? app : action.payload.data
      );
    },
    removeApp: (state, action) => {
      state.value = state.value.filter(
        (app) => app._id !== action.payload.index
      );
    },
    addUserToFavorites: (state, action) => {
      console.log("addUserToFavorites");
      console.log(action.payload);
      //   state.value = action.payload;
    },
    removeUserToFavorites: (state, action) => {
      console.log("removeUserToFavorites");
      console.log(action.payload);
      //   state.value = action.payload;
    },
  },
});

export const {
  showApps,
  addApp,
  updateApp,
  removeApp,
  addUserToFavorites,
  removeUserToFavorites,
} = appsSlice.actions;

export default appsSlice.reducer;
