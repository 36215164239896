import React from "react";

//bootstrap
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//components
import AppCard from "../components/AppCard";

const Tools = () => {
  return (
    <>
      <Container className="text-light">
        <h3>Tools</h3>
      </Container>
      <Container className="bg-dark py-2">
        <h4 className="text-light">HTML</h4>
        <Row>
          <Col>
            <AppCard
              cardTitle="SVG"
              cardText="Test creating an SVG"
              cardFooter="Last updated 3 mins ago"
              cardLink="/tools/svg"
            />
          </Col>
          <Col>
            <AppCard
              cardTitle="JSON"
              cardText="Visualize your JSON data"
              cardFooter="Last updated 3 mins ago"
              cardLink="/tools/json"
            />
          </Col>
          <Col>
            <AppCard
              cardTitle="API Tester"
              cardText="See the results of an API link"
              cardFooter="Last updated 3 mins ago"
              cardLink="/tools/apiTester"
            />
          </Col>
          <Col>
            <AppCard />
          </Col>
        </Row>
      </Container>
      <Container className="bg-dark py-2">
        <h4 className="text-light">CSS</h4>
        <Row>
          <Col>
            <AppCard />
          </Col>
          <Col>
            <AppCard />
          </Col>
          <Col>
            <AppCard />
          </Col>
        </Row>
      </Container>
      <Container className="bg-dark py-2">
        <h4 className="text-light">Javascript</h4>
        <Row>
          <Col>
            <AppCard />
          </Col>
          <Col>
            <AppCard />
          </Col>
          <Col>
            <AppCard />
          </Col>
        </Row>
      </Container>
      <Container className="bg-dark py-2">
        <h4 className="text-light">SVG</h4>
        <Row>
          <Col>
            <AppCard />
          </Col>
          <Col>
            <AppCard />
          </Col>
          <Col>
            <AppCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Tools;
