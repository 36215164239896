export const dateConstructors = [
  {
    dateConstructor: (date, ...params) => {
      if (params) {
        return date.toLocaleString(params[0], params[1]);
      }
      return date.toLocaleString();
    },
    constructorName: "toLocaleString",
    parameters: [
      {
        parameterName: "default",
        format: "toLocalString()",
        required: false,
        inputType: "blank",
        description: "",
        example: "",
      },
      {
        parameterName: "locales",
        format: "toLocalString(locales)",
        required: false,
        inputType: "string",
        description:
          "A string with a BCP 47 language tag, or an array of such strings.",
        example: "e.g. en-US or de-DE-u-co-phonebk",
      },
      {
        parameterName: "options",
        format: "toLocalString(locales, options)",
        required: false,
        inputType: "object",
        description: "",
        example: "",
        objectProps: [
          {
            propName: "dateStyle",
            inputType: "list",
            inputValues: ["full", "long", "medium", "short"],
            description: "",
            incompatibleProps: [
              "weekday",
              "hour",
              "month",
              "dayPeriod",
              "era",
              "year",
              "day",
              "minute",
              "second",
              "fractionalSecondDigits",
              "timeZoneName",
            ],
          },
          {
            propName: "timeStyle",
            inputType: "list",
            inputValues: ["full", "long", "medium", "short"],
            description: "",
            incompatibleProps: [
              "weekday",
              "hour",
              "month",
              "dayPeriod",
              "era",
              "year",
              "day",
              "minute",
              "second",
              "fractionalSecondDigits",
              "timeZoneName",
            ],
          },
          {
            propName: "calendar",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("calendar")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "dayPeriod",
            inputType: "list",
            inputValues: ["narrow", "short", "long"],
            description:
              'The formatting style used for day periods like "in the morning", "am", "noon", "n" etc.',
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "numberingSystem",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("numberingSystem")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "localeMatcher",
            inputType: "list",
            inputValues: ["best fit", "lookup"],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "timeZone",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("timeZone")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "hour12",
            inputType: "list",
            inputValues: ["true", "false"],
            description: "",
            incompatibleProps: ["hourCycle"],
          },
          {
            propName: "hourCycle",
            inputType: "list",
            inputValues: ["h11", "h12", "h23", "h24"],
            incompatibleProps: ["hour12"],
          },
          {
            propName: "formatMatcher",
            inputType: "list",
            inputValues: ["best fit", "basic"],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "weekday",
            inputType: "list",
            inputValues: ["long", "short", "narrow"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "era",
            inputType: "list",
            inputValues: ["long", "short", "narrow"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "year",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "month",
            inputType: "list",
            inputValues: ["numeric", "2-digit", "long", "short", "narrow"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "day",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "hour",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "minute",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "second",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "fractionalSecondDigits",
            inputType: "list",
            inputValues: ["1", "2", "3"],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
          {
            propName: "timeZoneName",
            inputType: "list",
            inputValues: [
              "long",
              "short",
              "shortOffset",
              "longOffset",
              "shortGeneric",
              "longGeneric",
            ],
            incompatibleProps: ["dateStyle", "timeStyle"],
          },
        ],
      },
    ],
    description: "",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.toLocaleString() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString",
      },
      {
        linkText: "Intl.DateTimeFormat() constructor - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat",
      },
    ],
  },
  {
    dateConstructor: (date, ...params) => {
      if (params) {
        return date.toLocaleDateString(params[0], params[1]);
      }
      return date.toLocaleDateString();
    },
    constructorName: "toLocaleDateString",
    parameters: [
      {
        parameterName: "default",
        format: "toLocalDateString()",
        required: false,
        inputType: "blank",
        description: "",
        example: "",
      },
      {
        parameterName: "locales",
        format: "toLocalDateString(locales)",
        required: false,
        inputType: "string",
        description:
          "A string with a BCP 47 language tag, or an array of such strings.",
        example: "e.g. en-US or de-DE-u-co-phonebk",
      },
      {
        parameterName: "options",
        format: "toLocalDateString(locales, options)",
        required: false,
        inputType: "object",
        description: "",
        example: "",
        objectProps: [
          {
            propName: "dateStyle",
            inputType: "list",
            inputValues: ["full", "long", "medium", "short"],
            description: "",
            incompatibleProps: [
              "weekday",
              "dayPeriod",
              "era",
              "year",
              "month",
              "day",
              "timeZoneName",
            ],
          },
          {
            propName: "calendar",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("calendar")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "dayPeriod",
            inputType: "list",
            inputValues: ["narrow", "short", "long"],
            description:
              'The formatting style used for day periods like "in the morning", "am", "noon", "n" etc.',
            incompatibleProps: ["dateStyle"],
          },
          {
            propName: "numberingSystem",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("numberingSystem")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "localeMatcher",
            inputType: "list",
            inputValues: ["best fit", "lookup"],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "timeZone",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("timeZone")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "formatMatcher",
            inputType: "list",
            inputValues: ["best fit", "basic"],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "weekday",
            inputType: "list",
            inputValues: ["long", "short", "narrow"],
            incompatibleProps: ["dateStyle"],
          },
          {
            propName: "era",
            inputType: "list",
            inputValues: ["long", "short", "narrow"],
            incompatibleProps: ["dateStyle"],
          },
          {
            propName: "year",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["dateStyle"],
          },
          {
            propName: "month",
            inputType: "list",
            inputValues: ["numeric", "2-digit", "long", "short", "narrow"],
            incompatibleProps: ["dateStyle"],
          },
          {
            propName: "day",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["dateStyle"],
          },
          {
            propName: "timeZoneName",
            inputType: "list",
            inputValues: [
              "long",
              "short",
              "shortOffset",
              "longOffset",
              "shortGeneric",
              "longGeneric",
            ],
            incompatibleProps: ["dateStyle"],
          },
        ],
      },
    ],
    description: "",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.toLocaleDateString() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString",
      },
      {
        linkText: "Intl.DateTimeFormat() constructor - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat",
      },
    ],
  },
  {
    dateConstructor: (date, ...params) => {
      if (params) {
        return date.toLocaleTimeString(params[0], params[1]);
      }
      return date.toLocaleTimeString();
    },
    constructorName: "toLocaleTimeString",
    parameters: [
      {
        parameterName: "default",
        format: "toLocalTimeString()",
        required: false,
        inputType: "blank",
        description: "",
        example: "",
      },
      {
        parameterName: "locales",
        format: "toLocalTimeString(locales)",
        required: false,
        inputType: "string",
        description:
          "A string with a BCP 47 language tag, or an array of such strings.",
        example: "e.g. en-US or de-DE-u-co-phonebk",
      },
      {
        parameterName: "options",
        format: "toLocalTimeString(locales, options)",
        required: false,
        inputType: "object",
        description: "",
        example: "",
        objectProps: [
          {
            propName: "timeStyle",
            inputType: "list",
            inputValues: ["full", "long", "medium", "short"],
            description: "",
            incompatibleProps: [
              "era",
              "month",
              "day",
              "hour",
              "minute",
              "second",
              "fractionalSecondDigits",
              "timeZoneName",
            ],
          },
          {
            propName: "calendar",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("calendar")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "numberingSystem",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("numberingSystem")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "timeZone",
            inputType: "collapse",
            inputValues: [...Intl.supportedValuesOf("timeZone")],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "hour12",
            inputType: "list",
            inputValues: ["true", "false"],
            description: "",
            incompatibleProps: ["hourCycle"],
          },
          {
            propName: "hourCycle",
            inputType: "list",
            inputValues: ["h11", "h12", "h23", "h24"],
            incompatibleProps: ["hour12"],
          },
          {
            propName: "formatMatcher",
            inputType: "list",
            inputValues: ["best fit", "basic"],
            description: "",
            incompatibleProps: [],
          },
          {
            propName: "era",
            inputType: "list",
            inputValues: ["long", "short", "narrow"],
            incompatibleProps: ["timeStyle"],
          },
          {
            propName: "month",
            inputType: "list",
            inputValues: ["numeric", "2-digit", "long", "short", "narrow"],
            incompatibleProps: ["timeStyle"],
          },
          {
            propName: "hour",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["timeStyle"],
          },
          {
            propName: "minute",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["timeStyle"],
          },
          {
            propName: "second",
            inputType: "list",
            inputValues: ["numeric", "2-digit"],
            incompatibleProps: ["timeStyle"],
          },
          {
            propName: "fractionalSecondDigits",
            inputType: "list",
            inputValues: ["1", "2", "3"],
            incompatibleProps: ["timeStyle"],
          },
          {
            propName: "timeZoneName",
            inputType: "list",
            inputValues: [
              "long",
              "short",
              "shortOffset",
              "longOffset",
              "shortGeneric",
              "longGeneric",
            ],
            incompatibleProps: ["timeStyle"],
          },
        ],
      },
    ],
    description: "",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.toLocaleTimeString() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString",
      },
      {
        linkText: "Intl.DateTimeFormat() constructor - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat",
      },
    ],
  },
  {
    dateConstructor: (date) => {
      return date.getDate();
    },
    constructorName: "getDate",
    description: "Numeric date of the month 1-31.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getDate() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDate",
      },
    ],
    category: "date",
  },
  {
    dateConstructor: (date) => {
      return date.getFullYear();
    },
    constructorName: "getFullYear",
    description: "Provides the full year from date obect.",
    note: "**Replaces .getYear() which only returned 2-digits and was an issue for dates crossing from year 1999 to 2000.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getFullYear() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getFullYear",
      },
    ],
    category: "date",
  },
  {
    dateConstructor: (date) => {
      return date.getMonth();
    },
    constructorName: "getMonth",
    description: "The month from 0-11.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getMonth() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth",
      },
    ],
    category: "date",
  },
  {
    dateConstructor: (date) => {
      return date.getDay();
    },
    constructorName: "getDay",
    description: "The day of the week from 0-6.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getDay() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay",
      },
    ],
    category: "date",
  },
  {
    dateConstructor: (date) => {
      return date.getHours();
    },
    constructorName: "getHours",
    description: "The hours from the date object.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getHours() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getHours",
      },
    ],
    category: "time",
  },
  {
    dateConstructor: (date) => {
      return date.getMinutes();
    },
    constructorName: "getMinutes",
    description: "The minutes from the date object.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getMinutes() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMinutes",
      },
    ],
    category: "time",
  },
  {
    dateConstructor: (date) => {
      return date.getSeconds();
    },
    constructorName: "getSeconds",
    description: "The seconds from the date object.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getSeconds() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getSeconds",
      },
    ],
    category: "time",
  },
  {
    dateConstructor: (date) => {
      return date.getMilliseconds();
    },
    constructorName: "getMilliseconds",
    description: "The milliseconds 0-999 from the date object.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getMilliseconds() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMilliseconds",
      },
    ],
    category: "time",
  },
  {
    dateConstructor: (date) => {
      return date.getTimezoneOffset();
    },
    constructorName: "getTimezoneOffset",
    description:
      "The difference in minutes from the UTC time and the local time.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.getTimezoneOffset() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset",
      },
    ],
    category: "time",
  },
  {
    dateConstructor: (date) => {
      return date.toString();
    },
    constructorName: "toString",
    description: "String representation of the date object.",
    note: "**overrides the Object .toString() method. Combines .toDateString() and toTimeString() with a space.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.toString() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toString",
      },
    ],
    category: "date",
  },
  {
    dateConstructor: (date) => {
      return date.toDateString();
    },
    constructorName: "toDateString",
    description: "Interpreted string of the date portion of a date object.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.toDateString() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toDateString",
      },
    ],
    category: "date",
  },
  {
    dateConstructor: (date) => {
      return date.toTimeString();
    },
    constructorName: "toTimeString",
    description: "Interpreted string of the time portion of a date object.",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.toTimeString() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toTimeString",
      },
    ],
    category: "time",
  },
  {
    dateConstructor: (date) => {
      return date.toISOString();
    },
    constructorName: "toISOString",
    description: "String of the date in ISO format (ISO 8601).",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.toISOString() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString",
      },
    ],
    category: "date",
  },
  {
    dateConstructor: (date) => {
      return date.toJSON();
    },
    constructorName: "toJSON",
    description: "",
    example: "",
    resources: [
      {
        linkText: "Date.prototype.toJSON() - JavaScript | MDN",
        linkUrl:
          "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toJSON",
      },
    ],
    category: "datetime",
  },
  // {
  //   dateConstructor: (date) => {
  //     return date.parse();
  //   },
  //   constructorName: "parse",
  //   parameters: [],
  //   description: "",
  //   example: "",
  //   resources: [],
  // },
];
