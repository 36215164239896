import React, { useState, useRef } from "react";

//bootstrap
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Image from "react-bootstrap/Image";

const ImagePopover = ({ imgLink }) => {
  const [show, setShow] = useState(false);
  const hoverRef = useRef(null);
  const popoverRef = useRef(null);
  const hoverTimeout = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();

    setShow(!show);
  };

  const handleMouseEnter = (event) => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setShow(true);
  };

  const handleMouseLeave = (event) => {
    hoverTimeout.current = setTimeout(() => {
      setShow(false);
    }, 300);
  };

  return (
    <span
      ref={hoverRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        // href="#"
        onClick={handleClick}
        className="link-light link-offset-2 link-offset-2-hover link-underline-opacity-25 link-underline-opacity-75-hover"
      >
        {imgLink}
      </div>
      <Overlay target={hoverRef} show={show}>
        <Popover
          placement="top-start"
          ref={popoverRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Popover.Body className="image-popover">
            <div className="d-flex justify-content-center mb-1">
              <Image src={imgLink} fluid className="m-1" />
            </div>
            <div className="border-top">
              <a
                href={imgLink}
                target="_blank"
                rel="noopener noreferrer"
                className="link-secondary link-offset-2 link-offset-2-hover link-underline-opacity-25 link-underline-opacity-75-hover"
              >
                {imgLink}
              </a>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </span>
  );
};

export default ImagePopover;
