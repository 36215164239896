import React, { useState, useEffect } from "react";

//bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Collapse from "react-bootstrap/esm/Collapse";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Popover from "react-bootstrap/Popover";
// import Image from "react-bootstrap/Image";

import {
  PlusSquare,
  Clipboard,
  DashSquare,
  GearFill,
  Gear,
  PlusCircleFill,
  DashCircleFill,
  TextWrap,
  ClipboardCheck,
  CaretDownSquareFill,
  CaretUpSquareFill,
} from "react-bootstrap-icons";

import FormField from "./FormField";

import "./ApiStore.css";
import ImagePopover from "./ImagePopover";

// local storage
import { getSingleAppData, saveAppData } from "../../../utils/localStorage";

// https://vpic.nhtsa.dot.gov/api/vehicles/getallmakes?format=json

const ApiStore = () => {
  const [userAppData, setUserAppData] = useState(getSingleAppData("ApiStore"));

  const [validated, setValidated] = useState(false);

  const [formInput, setFormInput] = useState({
    apiId: "",
    nameDropdown: "New API...",
    apiName: { name: "API Name", value: "" },
    domain: { name: "Domain", value: "" },
    location: { name: "Location", value: "" },
    apiKey: { name: "API Key", value: "" },
    suffix: { name: "suffix", value: "" },
    parameters: [],
    notes: "",
  });

  const [jsonOutputCollapse, setJsonOutputCollapse] = useState({});

  const [jsonOutputSettings, setJsonOutputSettings] = useState({
    settings: false,
    fontSize: 0.75,
    minHeight: 5,
    maxHeight: 30,
    maxResults: 10,
    wrap: false,
  });

  const [collapseState, setCollapseState] = useState({
    collapseApi: true,
    collapseParameters: true,
    collapseSuffix: true,
    collapseNotes: true,
  });

  const [apiUrl, setApiUrl] = useState("");
  const [copyText, setCopyText] = useState(false);

  const [fetchResults, setFetchResults] = useState("");
  const [editCount, setEditCount] = useState(3);
  const [edited, setEdited] = useState(3);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [jsonView, setJsonView] = useState("");

  //final url
  useEffect(() => {
    if (formInput.domain.value === "") {
      setApiUrl("");
      return;
    }

    let combinedUrl = formInput.domain.value;

    // adding http or https if needed
    if (!combinedUrl.match(/^(http:\/\/|https:\/\/)/)) {
      console.log("true");
      combinedUrl = "https://" + combinedUrl;
    }

    // adding forward slash if location needs to be added
    if (formInput.location.value) {
      if (
        combinedUrl.charAt(combinedUrl.length - 1) !== "/" &&
        formInput.location.value.charAt(0) !== "/"
      ) {
        combinedUrl += "/";
      }
      combinedUrl += formInput.location.value;
    }

    if (formInput.parameters.length > 0 && formInput.parameters[0].value) {
      formInput.parameters.forEach((parameter, i) => {
        if (parameter.value) {
          combinedUrl += i === 0 ? "?" : "&";
          combinedUrl += parameter.name + "=" + parameter.value;
        }
      });
    }

    if (formInput.suffix.value) {
      if (
        combinedUrl.charAt(combinedUrl.length - 1) !== "/" &&
        formInput.suffix.value.charAt(0) !== "/"
      ) {
        combinedUrl += "/";
      }
      combinedUrl += formInput.suffix.value;
    }

    setApiUrl(combinedUrl);
  }, [formInput]);

  useEffect(() => {
    if (formInput.apiId) {
      if (
        JSON.stringify(formInput) !==
        JSON.stringify(userAppData[formInput.apiId].formInput)
      ) {
        setEdited(true);
      } else {
        setEdited(false);
      }
    }
  }, [formInput]);

  useEffect(() => {
    if (fetchResults === "") {
      return;
    }

    setJsonView(jsonOutputFormat(fetchResults));
  }, [fetchResults, jsonOutputSettings, jsonOutputCollapse]);

  const handleAppDropdown = (eventKey) => {
    if (!eventKey) {
      return;
    }

    setValidated(false);

    if (eventKey === "New API...") {
      setFormInput({
        apiId: "",
        nameDropdown: "New API...",
        apiName: { name: "API Name", value: "" },
        domain: { name: "Domain", value: "" },
        location: {
          name: "Location",
          value: "",
        },
        apiKey: { name: "API Key", value: "" },
        suffix: { name: "suffix", value: "" },
        parameters: [],
        notes: "",
      });
      setEditCount(3);
    } else {
      const userApiData = userAppData[eventKey];

      setFormInput({
        apiId: userApiData.formInput.apiId,
        nameDropdown: userApiData.formInput.apiName.value,
        apiName: {
          name: "API Name",
          value: userApiData.formInput.apiName.value,
        },
        domain: { name: "Domain", value: userApiData.formInput.domain.value },
        location: {
          name: "Location",
          value: userApiData.formInput.location.value,
        },
        apiKey: { name: "API Key", value: userApiData.formInput.apiKey.value },
        suffix: { name: "suffix", value: userApiData.formInput.suffix.value },
        parameters: userApiData.formInput.parameters,
        notes: userApiData.formInput.notes,
      });
      setEditCount(0);
    }
  };

  const handleNotesChange = (event) => {
    setFormInput({ ...formInput, notes: event.target.value });
  };

  const handleAddParameter = (event) => {
    if (!event) {
      return;
    }

    let count = 1;
    const existingParams = new Set(formInput.parameters.map((p) => p.name));

    while (existingParams.has(`param-${count}`)) {
      count += 1;
    }

    const uniqueKey = `param-${count}`;

    setFormInput({
      ...formInput,
      parameters: [
        ...formInput.parameters,
        {
          name: uniqueKey,
          value: ``,
          newParameter: true,
        },
      ],
    });

    setEditCount(editCount + 1);
  };

  const handleSubCollapses = (event) => {
    console.log("handleSubCollapses");
    console.log(event.target.name);
    console.log(collapseState[event.target.name]);

    setCollapseState({
      ...collapseState,
      [event.target.name]: !collapseState[event.target.name],
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(apiUrl);

    setCopyText(true);

    setTimeout(() => {
      setCopyText(false);
    }, 3000);
  };

  const handleFetch = async () => {
    setFetchResults("");
    setJsonView("");
    setJsonOutputCollapse({});
    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error("something went wrong!");
      }
      const results = await response.json();

      setJsonOutputSettings({ ...jsonOutputSettings, maxResults: 20 });
      setFetchResults(results);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSaveApi = () => {
    if (!formInput.apiName.value || !formInput.domain.value) {
      setValidated(true);
    } else {
      setValidated(false);

      if (!formInput.apiId) {
        console.log(true);
        let newKey = 1;

        console.log(newKey);
        while (userAppData[newKey]) {
          console.log(newKey);
          newKey += 1;
        }

        console.log(newKey);
        saveAppData("ApiStore", {
          ...userAppData,
          [newKey]: {
            formInput: {
              ...formInput,
              apiId: newKey,
              nameDropdown: formInput.apiName.value,
              parameters: !formInput.parameters
                ? []
                : formInput.parameters.filter(
                    (parameter) => parameter.value !== ""
                  ),
            },
          },
        });

        setFormInput({
          ...formInput,
          apiId: newKey,
          nameDropdown: formInput.apiName.value,
        });
      } else {
        saveAppData("ApiStore", {
          ...userAppData,
          [formInput.apiId]: {
            formInput: {
              ...formInput,
              parameters: !formInput.parameters
                ? []
                : formInput.parameters.filter(
                    (parameter) => parameter.value !== ""
                  ),
            },
          },
        });
        setFormInput({ ...formInput, nameDropdown: formInput.apiName.value });
      }

      setUserAppData(getSingleAppData("ApiStore"));
    }
  };

  const handleDeleteApi = (event) => {
    console.log("handleDeleteApi");

    switch (event.target.name) {
      case "deleteApi":
        setConfirmDeletion(true);
        break;
      case "confirmDeletion":
        setConfirmDeletion(false);

        let updatedAppData = { ...userAppData };
        delete updatedAppData[formInput.apiId];
        saveAppData("ApiStore", updatedAppData);
        setUserAppData(updatedAppData);
        setFormInput({
          apiId: null,
          nameDropdown: "New API...",
          apiName: { name: "API Name", value: "" },
          domain: { name: "Domain", value: "" },
          location: {
            name: "Location",
            value: "",
          },
          apiKey: { name: "API Key", value: "" },
          suffix: { name: "suffix", value: "" },
          parameters: [],
          notes: "",
        });
        break;
      case "cancelDeletion":
        setConfirmDeletion(false);
        break;
      default:
        break;
    }
  };

  const handleResetApi = (event) => {
    const userApiData = userAppData[formInput.apiId].formInput;

    setFormInput({
      apiId: userApiData.apiId,
      nameDropdown: userApiData.apiName.value,
      apiName: {
        name: "API Name",
        value: userApiData.apiName.value,
      },
      domain: { name: "Domain", value: userApiData.domain.value },
      location: {
        name: "Location",
        value: userApiData.location.value,
      },
      apiKey: { name: "API Key", value: userApiData.apiKey.value },
      suffix: { name: "suffix", value: userApiData.suffix.value },
      parameters: userApiData.parameters,
      notes: userApiData.notes,
    });
    setEditCount(0);
  };

  const handleOutputChange = (event) => {
    switch (event.target.name) {
      case "settings":
        setJsonOutputSettings({
          ...jsonOutputSettings,
          settings: !jsonOutputSettings.settings,
        });
        break;
      case "fontIncrease":
        setJsonOutputSettings({
          ...jsonOutputSettings,
          fontSize: jsonOutputSettings.fontSize + 0.05,
        });
        break;
      case "fontDecrease":
        setJsonOutputSettings({
          ...jsonOutputSettings,
          fontSize: jsonOutputSettings.fontSize - 0.05,
        });
        break;
      case "increaseMaxResults":
        setJsonOutputSettings({
          ...jsonOutputSettings,
          maxResults: jsonOutputSettings.maxResults + 50,
        });
        break;
      case "showAllResults":
        setJsonOutputSettings({
          ...jsonOutputSettings,
          maxResults: "all",
        });
        break;
      case "wrap":
        setJsonOutputSettings({
          ...jsonOutputSettings,
          wrap: !jsonOutputSettings.wrap,
        });
        break;

      default:
        setJsonOutputSettings({
          ...jsonOutputSettings,
          settings: !jsonOutputSettings.settings,
        });
        break;
    }
  };

  const handleJsonCollapse = (event) => {
    console.log(event.target.name);
    setJsonOutputCollapse({
      ...jsonOutputCollapse,
      [event.target.name]: !jsonOutputCollapse[event.target.name],
    });
  };

  function jsonOutputFormat(jsonOutput) {
    let objectCount = 0;
    let collapseCount = 0;
    const updatedObj = (obj) => {
      if (Array.isArray(obj)) {
        if (objectCount > jsonOutputSettings.maxResults) {
          return obj.map((item, index) => {
            return updatedObj(item);
          });
        } else {
          collapseCount += 1;
          return (
            <div>
              <div className="d-flex align-items-center">
                <Button
                  size="sm"
                  variant="dark"
                  onClick={handleJsonCollapse}
                  name={`collapse${collapseCount}`}
                  className="d-flex justify-content-center align-items-center p-0"
                  title={
                    !jsonOutputCollapse[`collapse${collapseCount}`]
                      ? "collapse"
                      : "expand"
                  }
                  style={{
                    fontSize: `${jsonOutputSettings.fontSize}rem`,
                    lineHeight: "0",
                    height: `${jsonOutputSettings.fontSize}rem`,
                  }}
                >
                  {!jsonOutputCollapse[`collapse${collapseCount}`] ? (
                    <DashSquare className="pe-none" />
                  ) : (
                    <PlusSquare className="pe-none" />
                  )}
                </Button>
                <span>{"["}</span>
              </div>
              <div
                className={
                  !jsonOutputCollapse[`collapse${collapseCount}`]
                    ? ""
                    : "d-none"
                }
              >
                {obj.map((item, index) => {
                  return (
                    <div key={index} style={{ marginLeft: "1.25rem" }}>
                      {updatedObj(item)}
                    </div>
                  );
                })}
              </div>
              {`  ]`}
            </div>
          );
        }
      } else if (typeof obj === "object" && obj !== null) {
        if (objectCount > jsonOutputSettings.maxResults) {
          return Object.keys(obj).map((key, index) => {
            return updatedObj(obj[key]);
          });
        } else {
          collapseCount += 1;
          return (
            <div>
              <div className="d-flex align-items-center">
                <Button
                  size="sm"
                  variant="dark"
                  onClick={handleJsonCollapse}
                  name={`collapse${collapseCount}`}
                  className="d-flex justify-content-center align-items-center p-0"
                  title={
                    !jsonOutputCollapse[`collapse${collapseCount}`]
                      ? "collapse"
                      : "expand"
                  }
                  style={{
                    fontSize: `${jsonOutputSettings.fontSize}rem`,
                    lineHeight: "0",
                    height: `${jsonOutputSettings.fontSize}rem`,
                  }}
                >
                  {!jsonOutputCollapse[`collapse${collapseCount}`] ? (
                    <DashSquare className="pe-none" />
                  ) : (
                    <PlusSquare className="pe-none" />
                  )}
                </Button>
                <span>{"{"}</span>
              </div>
              <div
                className={
                  !jsonOutputCollapse[`collapse${collapseCount}`]
                    ? ""
                    : "d-none"
                }
              >
                {Object.keys(obj).map((key, index) => {
                  if (objectCount > jsonOutputSettings.maxResults) {
                    return updatedObj(obj[key]);
                  } else {
                    return (
                      <div key={index} style={{ marginLeft: "1.25rem" }}>
                        {objectCount + 1 > jsonOutputSettings.maxResults
                          ? null
                          : `"${key}": `}
                        {updatedObj(obj[key])}
                      </div>
                    );
                  }
                })}
              </div>
              {`  }`}
            </div>
          );
        }
      } else {
        objectCount += 1;
        if (objectCount > jsonOutputSettings.maxResults) {
          return null;
        } else if (/^https?:\/\/.*\.(jpeg|jpg|gif|png)$/i.test(obj)) {
          return <ImagePopover imgLink={obj} />;
        } else if (
          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(obj)
        ) {
          return (
            <span>
              <a
                href={obj}
                target="_blank"
                rel="noopener noreferrer"
                className="link-light link-offset-2 link-offset-2-hover link-underline-opacity-25 link-underline-opacity-75-hover"
              >
                {obj}
              </a>
            </span>
          );
        } else {
          return <span>{JSON.stringify(obj)}</span>;
        }
      }
    };

    const jsonHtml = updatedObj(jsonOutput);
    // setFetchCount(objectCount);
    return (
      <div>
        {jsonHtml}
        {jsonOutputSettings.maxResults !== "all" &&
          objectCount > jsonOutputSettings.maxResults && (
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                variant="dark"
                title="Show more"
                name="increaseMaxResults"
                onClick={handleOutputChange}
                className="m-1"
                style={{
                  fontSize: `${jsonOutputSettings.fontSize}rem`,
                  // lineHeight: "0",
                  // height: `${jsonOutputSettings.fontSize}rem`,
                }}
              >
                Show 50 more...
              </Button>
              <Button
                size="sm"
                variant="dark"
                title="Save all"
                name="showAllResults"
                onClick={handleOutputChange}
                className="m-1"
                style={{
                  fontSize: `${jsonOutputSettings.fontSize}rem`,
                  // lineHeight: "0",
                  // height: `${jsonOutputSettings.fontSize}rem`,
                }}
              >
                Show all ({objectCount})
              </Button>
            </div>
          )}
      </div>
    );
  }

  // --- prop functions
  function saveFieldValue(name, value) {
    // refactor opportunity
    switch (name) {
      case "API Name":
        setFormInput({
          ...formInput,
          apiName: { name: value.name, value: value.value },
        });
        break;
      case "Domain":
        setFormInput({
          ...formInput,
          domain: { name: value.name, value: value.value },
        });
        break;
      case "Location":
        setFormInput({
          ...formInput,
          location: { name: value.name, value: value.value },
        });
        break;
      case "API Key":
        setFormInput({
          ...formInput,
          apiKey: { name: value.name, value: value.value },
        });
        break;
      case "suffix":
        setFormInput({
          ...formInput,
          suffix: { name: value.name, value: value.value },
        });
        break;
      default:
        setFormInput({
          ...formInput,
          parameters: formInput.parameters.map((parameter) =>
            parameter.name === name
              ? { name: value.name, value: value.value }
              : parameter
          ),
        });
    }
  }

  function deleteFieldValue(name) {
    setFormInput({
      ...formInput,
      parameters: formInput.parameters.filter(
        (parameter) => parameter.name !== name
      ),
    });
  }

  function resetValidation() {
    setValidated(false);
  }

  function editMode(sumValue) {
    setEditCount(editCount + sumValue);
  }

  console.log(userAppData);

  return (
    <>
      <Container className="text-light pb-2">
        <h3>API Store</h3>
        <p>Review and store your REST API data.</p>
      </Container>
      <Container className="text-light pb-2">
        <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>API Input</Accordion.Header>
            <Accordion.Body>
              <Form noValidate validated={validated}>
                {/* dropdown */}
                <div className="border-bottom pb-1 mb-2 position-relative">
                  {/* collapse-button */}
                  <div className="position-absolute top-100 start-100 translate-middle">
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={handleSubCollapses}
                      name="collapseApi"
                      className="d-flex justify-content-center align-items-center p-0 rounded-circle icon-hover"
                      title="Collapse API"
                      style={{
                        fontSize: ".75rem",
                        height: ".75rem",
                      }}
                    >
                      {!jsonOutputSettings.settings ? (
                        <CaretDownSquareFill className="pe-none" />
                      ) : (
                        <CaretUpSquareFill className="pe-none " />
                      )}
                    </Button>
                  </div>
                  <DropdownButton
                    id="dropdown-button-dark-example2"
                    variant="dark"
                    menuVariant="dark"
                    title={formInput.nameDropdown}
                    onSelect={handleAppDropdown}
                    size="sm"
                    className="mb-2"
                    disabled={
                      (editCount !== 0 &&
                        formInput.nameDropdown !== "New API...") ||
                      confirmDeletion
                    }
                  >
                    <Dropdown.Item key={"new"} eventKey={"New API..."}>
                      New API...
                    </Dropdown.Item>
                    {Object.keys(userAppData).length > 0 && (
                      <Dropdown.Divider />
                    )}
                    {Object.entries(userAppData).map((appApi) => (
                      <Dropdown.Item
                        key={appApi[1].formInput.apiName.value}
                        eventKey={appApi[0]}
                      >
                        {appApi[1].formInput.apiName.value}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>

                {/* api fields */}
                <div
                  className={`border-bottom mb-2 position-relative ${
                    collapseState.collapseApi ? `pb-2` : `pb-0`
                  }`}
                >
                  {/* collapse-button */}
                  <div className="position-absolute top-100 start-100 translate-middle">
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={handleSubCollapses}
                      name="collapseParameters"
                      className="d-flex justify-content-center align-items-center p-0 rounded-circle icon-hover"
                      title="Collapse Parameters"
                      style={{
                        fontSize: ".75rem",
                        height: ".75rem",
                      }}
                    >
                      {!jsonOutputSettings.settings ? (
                        <CaretDownSquareFill className="pe-none" />
                      ) : (
                        <CaretUpSquareFill className="pe-none " />
                      )}
                    </Button>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6>API</h6>
                  </div>
                  <Collapse in={collapseState.collapseApi}>
                    <div>
                      <FormField
                        name={formInput.apiName.name}
                        value={formInput.apiName.value}
                        saveFieldValue={saveFieldValue}
                        deleteFieldValue={deleteFieldValue}
                        resetValidation={resetValidation}
                        editMode={editMode}
                        fieldValidation={validated}
                        parameter={false}
                        editOnNew={true}
                        nameDropdown={formInput.nameDropdown}
                      />
                      <FormField
                        name={formInput.domain.name}
                        value={formInput.domain.value}
                        saveFieldValue={saveFieldValue}
                        deleteFieldValue={deleteFieldValue}
                        resetValidation={resetValidation}
                        editMode={editMode}
                        type="url"
                        parameter={false}
                        editOnNew={true}
                        nameDropdown={formInput.nameDropdown}
                        fieldValidation={validated}
                      />
                      <FormField
                        name={formInput.location.name}
                        value={formInput.location.value}
                        saveFieldValue={saveFieldValue}
                        deleteFieldValue={deleteFieldValue}
                        resetValidation={resetValidation}
                        editMode={editMode}
                        parameter={false}
                        editOnNew={true}
                        nameDropdown={formInput.nameDropdown}
                      />
                      <FormField
                        name={formInput.apiKey.name}
                        value={formInput.apiKey.value}
                        saveFieldValue={saveFieldValue}
                        deleteFieldValue={deleteFieldValue}
                        resetValidation={resetValidation}
                        editMode={editMode}
                        parameter={false}
                      />
                    </div>
                  </Collapse>
                </div>

                {/* parameters */}
                <div
                  className={`border-bottom mb-2 position-relative ${
                    collapseState.collapseParameters ? `pb-2` : `pb-0`
                  }`}
                >
                  {/* collapse-button */}
                  <div className="position-absolute top-100 start-100 translate-middle">
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={handleSubCollapses}
                      name="collapseSuffix"
                      className="d-flex justify-content-center align-items-center p-0 rounded-circle icon-hover"
                      title="Collapse Suffix"
                      style={{
                        fontSize: ".75rem",
                        height: ".75rem",
                      }}
                    >
                      {!jsonOutputSettings.settings ? (
                        <CaretDownSquareFill className="pe-none" />
                      ) : (
                        <CaretUpSquareFill className="pe-none " />
                      )}
                    </Button>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6>Parameters</h6>
                    {collapseState.collapseParameters && (
                      <Button
                        size="sm"
                        variant="dark"
                        onClick={handleAddParameter}
                        name="addParameter"
                        className="d-flex justify-content-center align-items-center p-0 me-0 mt-1"
                        title="Add Parameter"
                        style={{
                          fontSize: "1rem",
                          height: "1rem",
                        }}
                      >
                        <PlusSquare className="pe-none" />
                      </Button>
                    )}
                  </div>
                  <Collapse in={collapseState.collapseParameters}>
                    <div>
                      {formInput.parameters.length !== 0 &&
                        formInput.parameters.map((parameter) => (
                          <FormField
                            key={parameter.name}
                            name={parameter.name}
                            value={
                              parameter?.newParameter ? `` : parameter.value
                            }
                            saveFieldValue={saveFieldValue}
                            deleteFieldValue={deleteFieldValue}
                            resetValidation={resetValidation}
                            newParameter={
                              parameter?.newParameter ? true : false
                            }
                            editMode={editMode}
                          />
                        ))}
                    </div>
                  </Collapse>
                </div>

                {/* url suffix */}
                <div
                  className={`border-bottom mb-2 position-relative ${
                    collapseState.collapseSuffix ? `pb-2` : `pb-0`
                  }`}
                >
                  {/* collapse-button */}
                  <div className="position-absolute top-100 start-100 translate-middle">
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={handleSubCollapses}
                      name="collapseNotes"
                      className="d-flex justify-content-center align-items-center p-0 rounded-circle icon-hover"
                      title="Collapse Notes"
                      style={{
                        fontSize: ".75rem",
                        height: ".75rem",
                      }}
                    >
                      {!jsonOutputSettings.settings ? (
                        <CaretDownSquareFill className="pe-none" />
                      ) : (
                        <CaretUpSquareFill className="pe-none " />
                      )}
                    </Button>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6>Url Suffix</h6>
                  </div>
                  <Collapse in={collapseState.collapseSuffix}>
                    <div>
                      <FormField
                        name={formInput.suffix.name}
                        value={formInput.suffix.value}
                        saveFieldValue={saveFieldValue}
                        deleteFieldValue={deleteFieldValue}
                        parameter={false}
                        resetValidation={resetValidation}
                        editMode={editMode}
                      />
                    </div>
                  </Collapse>
                </div>
              </Form>

              {/* notes */}
              <div
                className={`border-bottom mb-3 ${
                  collapseState.collapseNotes ? `pb-3` : `pb-0`
                }`}
              >
                <div className="d-flex justify-content-between">
                  <h6>Notes</h6>
                </div>
                <Collapse in={collapseState.collapseNotes}>
                  <Form.Group>
                    {/* <Form.Label>Notes</Form.Label> */}
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="font-monospace"
                      size="sm"
                      name="notes"
                      onChange={handleNotesChange}
                      value={formInput.notes}
                    />
                  </Form.Group>
                </Collapse>
              </div>

              {/* final url */}
              <InputGroup size="sm" className="mb-2">
                <Form.Control
                  type="url"
                  title=""
                  value={apiUrl}
                  readOnly
                  className="font-monospace"
                />
                <Button
                  variant={copyText ? "success" : "dark"}
                  onClick={handleCopy}
                  title="Copy"
                  name="copy"
                  className="d-flex justify-content-center align-items-center"
                  disabled={!apiUrl}
                >
                  {copyText ? (
                    <ClipboardCheck
                      className="pe-none"
                      style={{
                        transform: "scale(1.5)",
                      }}
                    />
                  ) : (
                    <Clipboard className="pe-none" />
                  )}
                </Button>
              </InputGroup>

              {/* fetch delete save buttons */}
              <div className="d-flex justify-content-between">
                <Button
                  size="sm"
                  variant="primary"
                  title="Fetch"
                  onClick={handleFetch}
                  className="me-1"
                  disabled={!apiUrl || editCount !== 0 || confirmDeletion}
                >
                  FETCH
                </Button>
                <div>
                  {formInput.nameDropdown !== "New API..." &&
                    !confirmDeletion &&
                    !edited && (
                      <Button
                        size="sm"
                        variant="dark"
                        title="Delete API"
                        onClick={handleDeleteApi}
                        className="me-1"
                        name="deleteApi"
                        disabled={editCount !== 0}
                      >
                        Delete API
                      </Button>
                    )}
                  {formInput.nameDropdown !== "New API..." &&
                    confirmDeletion && (
                      <Button
                        size="sm"
                        variant="danger"
                        title="Confirm Deletion"
                        onClick={handleDeleteApi}
                        className="me-1"
                        name="confirmDeletion"
                      >
                        Confirm Deletion
                      </Button>
                    )}
                  {confirmDeletion && (
                    <Button
                      size="sm"
                      variant="success"
                      title="Cancel Deletion"
                      onClick={handleDeleteApi}
                      name="cancelDeletion"
                    >
                      Cancel
                    </Button>
                  )}
                  {!confirmDeletion && edited && (
                    <Button
                      size="sm"
                      variant="dark"
                      title="Reset"
                      onClick={handleResetApi}
                      name="reset"
                      className="me-1"
                      disabled={!apiUrl || editCount !== 0}
                    >
                      Reset
                    </Button>
                  )}
                  {!confirmDeletion && (
                    <Button
                      size="sm"
                      variant={editCount !== 0 ? "dark" : "success"}
                      title="Save API"
                      onClick={handleSaveApi}
                      name="saveApi"
                      disabled={!apiUrl || editCount !== 0 || !edited}
                    >
                      Save API
                    </Button>
                  )}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>API Output JSON</Accordion.Header>
            <Accordion.Body className="position-relative px-2 pt-1">
              <div
                className="mb-1 d-flex align-items-center"
                style={{
                  fontSize: ".75rem",
                  height: ".75rem",
                }}
              >
                {fetchResults && (
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={handleOutputChange}
                    name="settings"
                    className="d-flex justify-content-center align-items-center p-0 rounded-circle me-3 icon-hover"
                    title="Display Settings"
                    style={{
                      fontSize: ".75rem",
                      height: ".75rem",
                    }}
                  >
                    {!jsonOutputSettings.settings ? (
                      <GearFill className="pe-none" />
                    ) : (
                      <Gear className="pe-none " />
                    )}
                  </Button>
                )}
                {jsonOutputSettings.settings && (
                  <Row className="align-items-center">
                    <Col className="d-flex align-items-center text-nowrap">
                      <span className="me-1">Font Size:</span>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={handleOutputChange}
                        name="fontIncrease"
                        className="d-flex justify-content-center align-items-center p-0 rounded-circle me-1 icon-hover"
                        title="Increase Font Size"
                        style={{
                          fontSize: ".75rem",
                          height: ".75rem",
                        }}
                      >
                        <PlusCircleFill className="pe-none" />
                      </Button>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={handleOutputChange}
                        name="fontDecrease"
                        className="d-flex justify-content-center align-items-center p-0 rounded-circle me-1 icon-hover"
                        title="Decrease Font Size"
                        style={{
                          fontSize: ".75rem",
                          height: ".75rem",
                        }}
                      >
                        <DashCircleFill className="pe-none" />
                      </Button>
                    </Col>
                    <Col className="d-flex align-items-center text-nowrap">
                      <span className="me-1">Wrap Text:</span>
                      <Button
                        size="sm"
                        variant="light"
                        onClick={handleOutputChange}
                        name="wrap"
                        className="d-flex justify-content-center align-items-center p-0 me-1"
                        title="Wrap Text"
                        style={{
                          fontSize: ".75rem",
                          height: ".75rem",
                        }}
                      >
                        <TextWrap className="pe-none" />
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
              <div
                className="json-output scroll-shadows"
                style={{
                  fontSize: `${jsonOutputSettings.fontSize}rem`,
                  minHeight: `${jsonOutputSettings.minHeight}rem`,
                  maxHeight: `${jsonOutputSettings.maxHeight}rem`,
                  overflow: "auto",
                }}
              >
                {/* <pre>{fetchResults}</pre> */}
                <pre
                  // style={{
                  //   overflow: "visible",
                  // }}
                  className={`mb-0 ${
                    jsonOutputSettings.wrap
                      ? "overflow-auto text-break text-wrap"
                      : "overflow-visible"
                  }`}
                >
                  {jsonView}
                </pre>
              </div>

              {/* <Form.Control
                as="textarea"
                rows={10}
                className="font-monospace text-white json-output"
                size="sm"
                value={fetchResults}
                readOnly
                style={{
                  fontSize: `${jsonOutputSettings.fontSize}rem`,
                }}
              /> */}
              {/* <pre className="font-monospace">
                {JSON.stringify(fetchResults, null, 2)}
              </pre> */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default ApiStore;
