export const svgAttributes = {
  attributes: [
    {
      attribute: "height",
      inputs: [
        {
          label: "Height",
          formType: "number",
          rangeFormOption: true,
        },
      ],
    },
    {
      attribute: "width",
      inputs: [
        {
          label: "Width",
          formType: "number",
          rangeFormOption: true,
        },
      ],
    },
    {
      attribute: "viewBox",
      inputs: [
        {
          label: "x",
          formType: "number",
          rangeFormOption: false,
        },
        {
          label: "y",
          formType: "number",
          rangeFormOption: false,
        },
        {
          label: "width",
          formType: "number",
          rangeFormOption: false,
        },
        {
          label: "height",
          formType: "number",
          rangeFormOption: false,
        },
      ],
    },
  ],
  elements: [
    {
      element: "Path",
      commands: [
        {
          command: "M",
          name: "moveto",
          moveType: "absolute",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
        },
        {
          command: "m",
          name: "moveto",
          moveType: "relative",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
        },
        {
          command: "L",
          name: "lineto",
          moveType: "absolute",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
        },
        {
          command: "l",
          name: "lineto",
          moveType: "relative",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
        },
        {
          command: "H",
          name: "horizontal lineto",
          moveType: "absolute",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
        },
        {
          command: "h",
          name: "horizontal lineto",
          moveType: "relative",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
        },
        {
          command: "V",
          name: "vertical lineto",
          moveType: "absolute",
          inputs: [
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
        },
        {
          command: "v",
          name: "vertical lineto",
          moveType: "relative",
          inputs: [
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
        },

        {
          command: "C",
          name: "curveto",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x1",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y1",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x2",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y2",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "absolute",
        },
        {
          command: "c",
          name: "curveto",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x1",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y1",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x2",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y2",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "relative",
        },
        {
          command: "S",
          name: "shorthand/smooth curveto",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x2",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y2",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "absolute",
        },
        {
          command: "s",
          name: "shorthand/smooth curveto",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x2",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y2",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "relative",
        },
        {
          command: "Q",
          name: "quadratic Bézier curveto	",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x1",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y1",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "absolute",
        },
        {
          command: "q",
          name: "quadratic Bézier curveto	",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x1",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y1",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "relative",
        },
        {
          command: "T",
          name: "Shorthand/smooth quadratic Bézier curveto",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "absolute",
        },
        {
          command: "t",
          name: "Shorthand/smooth quadratic Bézier curveto",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "relative",
        },
        {
          command: "A",
          name: "elliptical arc",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "x-axis-rotation(degrees)",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "largeArcFlag",
              formType: "dropdown",
              rangeFormOption: false,
              tooltip: "",
              options: ["0", "1"],
            },
            {
              label: "sweepFlag",
              formType: "dropdown",
              rangeFormOption: false,
              tooltip: "",
              options: ["0", "1"],
            },
            {
              label: "ry",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "rx",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "ry",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "absolute",
        },
        {
          command: "a",
          name: "elliptical arc",
          inputs: [
            {
              label: "rx",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "ry",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "relative",
        },
      ],
    },
    {
      element: "Rectangle",
      commands: [
        {
          command: "M",
          name: "moveto",
          inputs: [
            {
              label: "x",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
            {
              label: "y",
              formType: "number",
              rangeFormOption: false,
              tooltip: "",
            },
          ],
          moveType: "absolute",
        },
      ],
    },
    { element: "Circle", commands: [] },
    { element: "Ellipse", commands: [] },
    { element: "Line", commands: [] },
    { element: "Polyline", commands: [] },
    { element: "Polygon", commands: [] },
    { element: "Text", commands: [] },
  ],
};
