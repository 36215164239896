//react
import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";

//debounce
import debounce from "lodash.debounce";

//bootstrap
import {
  Container,
  Stack,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  OverlayTrigger,
  Popover,
  Table,
  Accordion,
} from "react-bootstrap";

import { svgAttributes } from "./svg-data";

const SVG = () => {
  console.log(svgAttributes);

  // STATE
  const [formSVGState, setFormSVGState] = useState({
    type: "Path",
    canvasBackgroundColor: "#6C757D",
    height: "210",
    width: "400",
    fill: true,
    fillColor: "#563d7c",
    stroke: false,
    strokeColor: "#000000",
    strokeSize: "10",
    strokeDashArray: "",
    pathSteps: ["M150 0", "L75 200", "L225 200"],
    path: ["M150 0", "L75 200", "L225 200"],
  });

  const [svgTypeDropdownState, setSvgTypeDropdownState] = useState("Path");
  const [addStepDropdownState, setAddStepDropdownState] = useState("L");

  // FORM UPDATE AND DEBOUNCE
  // update state based on form input changes
  const handleChange = (event) => {
    let { name, value, type } = event.target;

    //TODO: refactor
    if (name === "svgReset") {
      event.target.previousSibling.value = "M150 0 L75 200 L225 200 Z";
      name = "path";
      value = ["M150 0", "L75 200", "L225 200"];
    } else if (name === "strokeSize") {
      if (type === "text" && event.target.nextSibling !== null) {
        if (event.target.nextSibling.type === "range") {
          event.target.nextSibling.value = value;
        }
      } else {
        value = Math.round(value);
        event.target.previousSibling.value = value;
      }
    } else if (type === "range") {
      value = (value / 100) * 1000;
      event.target.previousSibling.value = value;
    } else if (type === "text" && event.target.nextSibling !== null) {
      if (event.target.nextSibling.type === "range") {
        event.target.nextSibling.value = (value / 1000) * 100;
      }
    } else if (type === "checkbox") {
      value = event.target.checked;
    }

    changeDelayHandler(name, value);
    // setFormSVGState({
    //   ...formSVGState,
    //   [name]: value,
    // });
  };

  const setForm = (name, value) => {
    setFormSVGState({
      ...formSVGState,
      [name]: value,
    });
  };

  const changeDelayHandler = useMemo(
    () => debounce(setForm, 500),
    [formSVGState]
  );

  // DROPDOWNS
  // updates to dropdown titles
  const handleDropdown = (eventKey, event) => {
    if (!eventKey) {
      return;
    }

    console.log(eventKey);
    switch (event.target.parentNode.previousSibling.id) {
      case "svgTypeDropdown":
        setSvgTypeDropdownState(eventKey);
        break;

      case "addSVGStepDropdown":
        setAddStepDropdownState(eventKey);
        break;
      default:
        return;
    }
  };

  // SVG STEPS
  const handleStep = (event) => {
    // const inputArray = [...stepInputGroup].filter((el) => el.type === "text");
    console.log(event.target.textContent);

    const siblingArray = event.target.offsetParent.children;

    let addToPath = siblingArray[0].textContent;

    for (let i = 1; i < siblingArray.length; i++) {
      if (siblingArray[i].type === "text" && !siblingArray[i].value) {
        return;
      } else if (siblingArray[i].type === "text") {
        if (addToPath.length === 1) {
          addToPath = addToPath + siblingArray[i].value;
          console.log(addToPath);
        } else {
          addToPath = addToPath + " " + siblingArray[i].value;
        }
      }
    }

    console.log("Step: " + addToPath);

    console.log([...formSVGState.path, addToPath]);

    setFormSVGState({
      ...formSVGState,
      path: [...formSVGState.path, addToPath],
    });
  };

  return (
    <>
      <Container className="text-light pt-2">
        <h3>SVG</h3>
        <p>Change the filters to see how it changes the SVG.</p>
      </Container>
      <Container className="text-light mb-2">
        <Accordion alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="fs-6 fw-bold">SVG Setup</div>
            </Accordion.Header>
            <Accordion.Body>
              <Form className="form-dark">
                <Row className="mb-3">
                  <Col>
                    <DropdownButton
                      className="w-100 mt-1"
                      as={ButtonGroup}
                      id="svgTypeDropdown"
                      variant="dark"
                      size="sm"
                      title={svgTypeDropdownState}
                      menuVariant="dark"
                      onSelect={handleDropdown}
                    >
                      {svgAttributes.elements &&
                        svgAttributes.elements.map((e) => {
                          return (
                            <Dropdown.Item
                              key={e.element}
                              eventKey={e.element}
                              active={svgTypeDropdownState === e.element}
                            >
                              {e.element}
                            </Dropdown.Item>
                          );
                        })}
                    </DropdownButton>
                  </Col>
                  <Form.Group as={Col}>
                    <InputGroup
                      size="sm"
                      className="mt-1 input-group-color-picker"
                    >
                      <InputGroup.Text>Canvas Color</InputGroup.Text>

                      <Form.Control
                        id="formCanvasColorSelector"
                        type="color"
                        aria-label="Canvas color selector"
                        defaultValue={formSVGState.canvasBackgroundColor}
                        title="Select color"
                        name="canvasBackgroundColor"
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <InputGroup size="sm">
                      <InputGroup.Text>Height</InputGroup.Text>
                      <Form.Control
                        aria-label="Height input box"
                        className="rounded-end "
                        type="text"
                        name="height"
                        defaultValue={formSVGState.height}
                        onChange={handleChange}
                        id="formSvgHeightInput"
                      />
                      <Form.Range
                        name="height"
                        defaultValue={(formSVGState.height / 1000) * 100}
                        onChange={handleChange}
                        id="formSvgHeightRng"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <InputGroup size="sm">
                      <InputGroup.Text>Width</InputGroup.Text>
                      <Form.Control
                        aria-label="Width input box"
                        className="rounded-end "
                        type="text"
                        name="width"
                        defaultValue={formSVGState.width}
                        onChange={handleChange}
                        id="formSvgWidthInput"
                      />
                      <Form.Range
                        name="width"
                        defaultValue={(formSVGState.width / 1000) * 100}
                        onChange={handleChange}
                        id="formSvgWidthRng"
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} sm={4} className="mb-2">
                    <InputGroup size="sm">
                      <InputGroup.Text>Fill</InputGroup.Text>
                      <InputGroup.Checkbox
                        id="formFillCheckbox"
                        name="fill"
                        onChange={handleChange}
                        checked={formSVGState.fill}
                      />
                      <Form.Control
                        id="formFillColorSelector"
                        type="color"
                        aria-label="Fill color selector"
                        defaultValue={formSVGState.fillColor}
                        title="Select color"
                        name="fillColor"
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Col></Col>
                  <Form.Group as={Col} sm={7}>
                    <InputGroup size="sm">
                      <InputGroup.Text>Stroke</InputGroup.Text>
                      <InputGroup.Checkbox
                        id="formStrokeCheckbox"
                        name="stroke"
                        onChange={handleChange}
                        checked={formSVGState.stroke}
                      />
                      <Form.Control
                        id="formStrokeColorSelector"
                        type="color"
                        aria-label="Stroke color selector"
                        defaultValue="#563d7c"
                        title="Select color"
                        name="strokeColor"
                        onChange={handleChange}
                      />
                      <InputGroup.Text>Size</InputGroup.Text>
                      <Form.Control
                        id="formSvgStrokeSizeInput"
                        size="sm"
                        aria-label="Stroke size input box"
                        className="rounded-end "
                        type="text"
                        name="strokeSize"
                        defaultValue={formSVGState.strokeSize}
                        onChange={handleChange}
                      />
                      <Form.Range
                        id="formSvgStrokeSizeRng"
                        defaultValue={formSVGState.strokeSize}
                        name="strokeSize"
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Col></Col>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <InputGroup size="sm">
                      <InputGroup.Text id="formPathInputLabel">
                        SVG
                      </InputGroup.Text>
                      <Form.Control
                        as="textarea"
                        id="formPathInput"
                        type="text "
                        aria-describedby="Path input box"
                        readOnly={true}
                        value={`<SVG height="${formSVGState.height}" width="${
                          formSVGState.width
                        }" fill="${
                          !formSVGState.fill ? "none" : formSVGState.fillColor
                        }" stroke="${
                          !formSVGState.stroke
                            ? "none"
                            : formSVGState.strokeColor
                        }" stroke-width="${
                          formSVGState.strokeSize
                        }"><path d="${formSVGState.path
                          .toString()
                          .replace(/,/g, " ")} Z"></path></svg>`}
                      />
                      <Button
                        variant="dark"
                        id="formPathResetBtn"
                        name="copy"
                        onClick={(event) =>
                          navigator.clipboard.writeText(
                            event.target.previousSibling.value
                          )
                        }
                      >
                        Copy
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="fs-6 fw-bold">SVG Build</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="justify-content-between">
                <Form.Group>
                  <Stack gap={2}>
                    <div className="d-flex justify-content-between">
                      <InputGroup size="sm" className="mb-4 svgStepList">
                        {/* <DropdownButton
                          variant="dark"
                          drop="start"
                          title={addStepDropdownState}
                          id="addSVGStepDropdown"
                          onSelect={handleDropdown}
                        >
                          <Dropdown.Header>Move To</Dropdown.Header>
                          <Dropdown.Item
                            eventKey="M"
                            active={addStepDropdownState === "M"}
                          >
                            M
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Header>Lines</Dropdown.Header>
                          <Dropdown.Item
                            eventKey="L"
                            active={addStepDropdownState === "L"}
                          >
                            L
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="H"
                            active={addStepDropdownState === "H"}
                          >
                            H
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="V"
                            active={addStepDropdownState === "V"}
                          >
                            V
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Header>Curves</Dropdown.Header>
                          <Dropdown.Item
                            eventKey="C"
                            active={addStepDropdownState === "C"}
                          >
                            C
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="S"
                            active={addStepDropdownState === "S"}
                          >
                            S
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Q"
                            active={addStepDropdownState === "Q"}
                          >
                            Q
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="T"
                            active={addStepDropdownState === "T"}
                          >
                            T
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Header>Arc</Dropdown.Header>
                          <Dropdown.Item
                            eventKey="A"
                            active={addStepDropdownState === "A"}
                          >
                            A
                          </Dropdown.Item>
                        </DropdownButton> */}
                        <DropdownButton
                          as={ButtonGroup}
                          variant="dark"
                          menuVariant="dark"
                          drop="start"
                          size="sm"
                          title={addStepDropdownState}
                          id="addSVGStepDropdown"
                          onSelect={handleDropdown}
                          className="scrollable"
                        >
                          {svgAttributes.elements &&
                            svgAttributes.elements.map((e) =>
                              e.element === svgTypeDropdownState
                                ? e.commands.map((c) => {
                                    return (
                                      <Dropdown.Item
                                        key={c.command}
                                        eventKey={c.command}
                                        active={
                                          addStepDropdownState === c.command
                                        }
                                      >
                                        {c.command}
                                      </Dropdown.Item>
                                    );
                                  })
                                : null
                            )}
                        </DropdownButton>

                        {svgAttributes.elements &&
                          svgAttributes.elements.map((e) =>
                            e.element === svgTypeDropdownState
                              ? e.commands.map((c) =>
                                  c.command === addStepDropdownState
                                    ? c.inputs.map((i) => {
                                        return (
                                          <>
                                            <InputGroup.Text>
                                              {i.label}
                                            </InputGroup.Text>
                                            <Form.Control />
                                          </>
                                        );
                                      })
                                    : null
                                )
                              : null
                          )}
                        <Button
                          variant="dark"
                          id="formPathResetBtn"
                          onClick={handleStep}
                        >
                          Add
                        </Button>
                      </InputGroup>
                      <div>
                        <OverlayTrigger
                          trigger={["focus", "click"]}
                          rootClose="true"
                          placement="right"
                          delay="500"
                          overlay={
                            <Popover variant="dark" id="info">
                              <Popover.Header as="h3">SVG Key</Popover.Header>
                              <Popover.Body>
                                <Table
                                  variant="dark"
                                  striped
                                  bordered
                                  size="sm"
                                >
                                  <thead>
                                    <tr>
                                      <th>Command</th>
                                      <th>Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>M</td>
                                      <td>Move to (Starting Point Position)</td>
                                    </tr>
                                    <tr>
                                      <td>L</td>
                                      <td>Line To</td>
                                    </tr>
                                    <tr>
                                      <td>H</td>
                                      <td>Horizontal Line To</td>
                                    </tr>
                                    <tr>
                                      <td>V</td>
                                      <td>Vertical Line To</td>
                                    </tr>
                                    <tr>
                                      <td>C</td>
                                      <td>Cubic Curve To</td>
                                    </tr>
                                    <tr>
                                      <td>S</td>
                                      <td>Shorthand/Smooth Cubic Curve To</td>
                                    </tr>
                                    <tr>
                                      <td>Q</td>
                                      <td>Quadratic Bézier Curve To</td>
                                    </tr>
                                    <tr>
                                      <td>T</td>
                                      <td>
                                        Shorthand/Smooth Quadratic Bézier Curve
                                        To
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>A</td>
                                      <td>Elliptical Arc</td>
                                    </tr>
                                    <tr>
                                      <td>Z</td>
                                      <td>Close Path (End of the path)</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <span>source: </span>
                                <Link to="https://www.w3.org/TR/SVG/paths.html#PathDataMovetoCommands">
                                  www.w3.org
                                </Link>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <Button
                            variant="dark"
                            id="addToSVGInfoIcon"
                            name="info"
                            className="info-icon m-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-info-circle info-icon"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div>
                      <div className="fs-6 fw-bold">Steps</div>
                    </div>
                    {formSVGState.path &&
                      formSVGState.path.map((step, i) => (
                        <InputGroup
                          size="sm"
                          key={i}
                          id={`svgStepItem-${i}`}
                          className="svgStepList"
                        >
                          <InputGroup.Text>
                            {step.substring(0, 1)}
                          </InputGroup.Text>
                          <InputGroup.Text>X</InputGroup.Text>
                          <Form.Control
                            value={
                              step.substring(0, 1) === "M" ||
                              step.substring(0, 1) === "L"
                                ? step.split(" ")[0].substring(1)
                                : "??"
                            }
                            readOnly={true}
                          />
                          <InputGroup.Text>Y</InputGroup.Text>
                          <Form.Control
                            value={
                              step.substring(0, 1) === "M" ||
                              step.substring(0, 1) === "L"
                                ? step.split(" ")[1]
                                : "??"
                            }
                            readOnly={true}
                          />
                          <Button
                            variant="dark"
                            id="formPathResetBtn"
                            onClick={handleStep}
                          >
                            Edit
                          </Button>
                        </InputGroup>
                      ))}
                  </Stack>
                </Form.Group>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <Container>
        <div className="text-light border rounded border-light mb-2 p-1">
          {formSVGState.path ? (
            <svg
              style={{
                "--bs-border-opacity": 0.75,
                backgroundColor: formSVGState.canvasBackgroundColor,
              }}
              className="border border-secondary"
              height={formSVGState.height}
              width={formSVGState.width}
              fill={formSVGState.fillColor}
              stroke={
                formSVGState.stroke ? formSVGState.strokeColor : "transparent"
              }
              strokeWidth={
                formSVGState.stroke ? formSVGState.strokeSize + "px" : "0px"
              }
            >
              <path
                d={`${formSVGState.path.toString().replace(/,/g, " ")} Z`}
              />
              <circle cx="10" cy="10" r="1" fill="red"></circle>
            </svg>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default SVG;
