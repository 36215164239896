import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { HeartFill, Heart } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";

import { useMutation } from "@apollo/client";

import Auth from "../utils/auth";

// store
// import { addUserToFavorites, removeUserToFavorites } from "../apps/appsSlice";
import { addFavorite, removeFavorite } from "../store/profile/profileSlice";

import { ADD_FAVORITE, REMOVE_FAVORITE } from "../utils/mutations";

// local storage
import { updateProfileData } from "../utils/localStorage";

const authLoggedIn = Auth.loggedIn();

export default function Favorite({ appId }) {
  const userFavorites = useSelector((state) => state.profile.favoriteApps);
  const dispatch = useDispatch();

  const [addFavoritesToDb] = useMutation(ADD_FAVORITE);

  const [removeFavoritesFromDb] = useMutation(REMOVE_FAVORITE);

  // console.log("userFavorites");
  // console.log(userFavorites);
  // console.log("appId");
  // console.log(appId);

  const handleFavoriteClick = (event) => {
    event.preventDefault();

    const { name } = event.currentTarget;
    const { favorite } = event.currentTarget.dataset;

    if (name) {
      if (favorite === "true") {
        updateProfileData(
          "favoriteApps",
          userFavorites.filter((id) => {
            return id !== name;
          })
        );
        dispatch(removeFavorite(name));
      } else {
        updateProfileData("favoriteApps", [...userFavorites, name]);
        dispatch(addFavorite(name));
      }

      // saving profile data to local storage
      // updateProfileData("favoriteApps", [...userFavorites, name]);

      try {
        if (favorite) {
          removeFavoritesFromDb({
            variables: { appId: name },
          });
        } else {
          addFavoritesToDb({
            variables: { appId: name },
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div>
      {!userFavorites ? (
        <div>loading...</div>
      ) : userFavorites.includes(appId) ? (
        <Button
          name={appId}
          variant="dark"
          data-favorite={true}
          className="button favorite-icon"
          // onClick={() => dispatch(removeFavorite(appId))}
          onClick={handleFavoriteClick}
          disabled={!authLoggedIn}
        >
          <HeartFill />
        </Button>
      ) : (
        <Button
          name={appId}
          variant="dark"
          data-favorite={false}
          className="button favorite-icon"
          // onClick={() => dispatch(addFavorite(appId))}
          onClick={handleFavoriteClick}
          disabled={!authLoggedIn}
        >
          <Heart />
        </Button>
      )}
    </div>
  );
}
