//react
import React, { useState, useEffect } from "react";

//bootstrap
import { Container, Row, Col, Button } from "react-bootstrap";

// components
import Timer from "../Timer";

const ClickCounter = () => {
  // STATE
  const [clickCountState, setClickCountState] = useState(0);
  const [timerToggle, setTimerToggle] = useState(false);

  //   const [timer, setTimer] = useState({
  //     hours: "00",
  //     minutes: "00",
  //     seconds: "00",
  //     milliseconds: "0000",
  //   });

  const startTimer = (event) => {
    console.log(event.target);
    setTimerToggle(!timerToggle);
  };

  //   const handleChange = (event) => {
  //     console.log(event.target);
  //   };

  return (
    <>
      <Container className="text-light pb-2">
        <h3>ClickCounter</h3>
        <p>Visualize your ClickCounter data.</p>
      </Container>
      <Container className="text-light pb-2">
        <Row className="mb-3">
          <Col xs={4}>
            <div className="border rounded border-light p-3 h-100">
              <div className="fs-1 ">
                <Timer interval={10} timerToggle={timerToggle} />
              </div>
              <div className="fs-1">Clicks: {clickCountState}</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="border rounded border-light p-3 h-100 d-flex justify-content-center align-items-center">
              <br />
              <Button variant="secondary" onClick={startTimer} size="lg">
                Primary
              </Button>
            </div>
          </Col>
          <Col xs={4}>
            <div className="border rounded border-light p-3 h-100"></div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClickCounter;
