// import React, { useState } from "react";
// import { Button, Collapse } from "react-bootstrap";

// function Example() {
//   const [open, setOpen] = useState(false);

//   const handleEntering = (node, isAppearing) => {
//     console.log("Collapse animation starting...");
//     console.log("Collapsing element:", node);
//     console.log("Is appearing:", isAppearing);
//   };

//   const handleExiting = (node) => {
//     console.log("Collapse animation ending...");
//     console.log("Collapsing element:", node);
//   };

//   return (
//     <div>
//       <Button
//         onClick={() => setOpen(!open)}
//         aria-controls="example-collapse"
//         aria-expanded={open}
//       >
//         Toggle Collapse
//       </Button>
//       <Collapse in={open} onEntering={handleEntering} onExiting={handleExiting}>
//         <div id="example-collapse">
//           {/* Collapsible content */}
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//         </div>
//       </Collapse>
//     </div>
//   );
// }

// export default Example;

// import React, { useState } from "react";
// import { Button, Collapse, Transition } from "react-bootstrap";

// function Example() {
//   const [open, setOpen] = useState(false);

//   const handleEntering = () => {
//     console.log("Collapse animation starting...");
//   };

//   const handleExiting = () => {
//     console.log("Collapse animation ending...");
//   };

//   return (
//     <div>
//       <Button
//         onClick={() => setOpen(!open)}
//         aria-controls="example-collapse"
//         aria-expanded={open}
//       >
//         Toggle Collapse
//       </Button>
//       <Transition
//         in={open}
//         onEnter={handleEntering}
//         onExit={handleExiting}
//         timeout={300} // Duration of the animation in milliseconds
//       >
//         {(state) => (
//           <div
//             id="example-collapse"
//             style={{
//               height: state === "entered" ? "auto" : 0,
//               overflow: "hidden",
//               transition: "height 300ms",
//             }}
//           >
//             {/* Collapsible content */}
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//           </div>
//         )}
//       </Transition>
//     </div>
//   );
// }

// export default Example;

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

// import { dateConstructors } from "./DateTimeFormat-data";

function Example() {
  const [open, setOpen] = useState(false);

  //   function onEntering(e1, e2) {
  //     console.log(`collapsing: ${e2}`);
  //   }

  // let compatible = new Set();

  // const allKeys = dateConstructors.filter(
  //   (c) => c.constructorName === "toLocaleString"
  // );

  // const allKeys = dateConstructors
  //   .find((c) => c.constructorName === "toLocaleString")
  //   ?.parameters.find((p) => p.parameterName === "options")
  //   ?.objectProps.map((o) => o.propName);

  // console.log(allKeys);

  // compatible = allKeys;
  // console.log(compatible);
  // let incompatibleList = [
  //   "weekday",
  //   "hour",
  //   "month",
  //   "dayPeriod",
  //   "era",
  //   "year",
  //   "day",
  //   "second",
  //   "fractionalSecondDigits",
  //   "timeZoneName",
  // ];

  const [incompatibleList, setIncompatibleList] = useState({});

  const handleClick = (incompatible) => {
    // let newIncompatibleList = { ...incompatibleList };

    // incompatible.forEach((o) => {
    //   newIncompatibleList = {
    //     ...newIncompatibleList,
    //     [o]: !newIncompatibleList[o] ? 1 : (newIncompatibleList[o] += 1),
    //   };
    // });

    const newIncompatibleList = incompatible.reduce(
      (updatedIncompatibleList, key) => {
        updatedIncompatibleList.hasOwnProperty(key)
          ? (updatedIncompatibleList[key] += 1)
          : (updatedIncompatibleList[key] = 1);
        return updatedIncompatibleList;
      },
      {
        ...incompatibleList,
      }
    );

    setIncompatibleList(newIncompatibleList);
  };

  console.log(incompatibleList);

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        click
      </Button>
      <Collapse in={open}>
        <div id="example-collapse-text" className="text-white">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
          labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </Collapse>
      <div
        className="container"
        style={{
          backgroundColor: "yellow",
          padding: "10px",
          position: "relative",
        }}
      >
        <div
          className="static-div"
          style={{
            backgroundColor: "red",
            padding: "5px",
            height: "100px",
            width: "100px",
            position: "RELATIVE",
            zIndex: "2",
          }}
        >
          RELATIVE
          {/* <!-- Content of the static-positioned div --> */}
        </div>
        <div
          className="absolute-div"
          style={{
            backgroundColor: "blue",
            padding: "5px",
            height: "100px",
            width: "100px",
            position: "absolute",
            left: "30px",
            top: "30px",
            zIndex: "1",
          }}
        >
          ABSOLUTE
          {/* <!-- Content of the absolute-positioned div --> */}
        </div>
      </div>
      <p></p>
      <Button
        onClick={() =>
          handleClick([
            "era2",
            "year3",
            "day2",
            "second",
            "fractionalSecondDigits",
            "timeZoneName",
          ])
        }
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        Click2
      </Button>
      {Object.entries(incompatibleList).map(([key, value]) => {
        <div key="key">key: value</div>;
      })}
    </>
  );
}

export default Example;
