import React, { useState, useEffect } from "react";

//bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//bootstrap-icons
import { Download } from "react-bootstrap-icons";

import "./PwaTester.css";

//to store the beoreinstallPrompt event
let promptEvent = null;

const TestPage = ({ page }) => {
  // toggle anything state
  const [toggleStyle, setToggleStyle] = useState({});

  const [pwaPrompt, setPwaPrompt] = useState({
    displayMode: {
      code: `window.matchMedia("(display-mode: standalone)").matches`,
      results: window.matchMedia("(display-mode: standalone)").matches,
      info: "matchMedia() is the javascript equivalent to a css media query. If 'display-mode: standalone' is true, the application is opened as a PWA, not in a browser",
    },
    navigatorStandalone: {
      code: `navigator.standalone`,
      results: navigator.standalone,
      info: `Older browser versions do not support 'display-mode: standalone'. 'navigator.standalone' is an alternative to also determine if the application was opened as a PWA`,
    },
    documentReferrer: {
      code: `document.referrer`,
      results: document.referrer,
      info: "If document.referrer starts with 'android-app://', the pwa is considered a google android 'trusted-web-app(twa)'",
    },
    launchMode: {
      code: `if (document.referrer.startsWith("android-app://")) {
        return "twa";
      } else if (navigator.standalone || window.matchMedia("(display-mode: standalone)").matches) {
        return "standalone";
      } else {

      return "browser"}`,
      results: ``,
      info: "Possible method to determine how how the application is launched.",
    },
    installed: {
      code: `window.addEventListener("appinstalled", () => {(insert code)}`,
      results: false,
      info: `An event listener 'appinstalled' can update the app to suppress install prompts or more to customize the experience.`,
    },
    beforeinstallEvent: {
      code: `window.addEventListener("beforeinstallprompt", (event) => {(insert code)}`,
      results: !promptEvent
        ? `null`
        : `event object:
      ${promptEvent}`,
      info: `Use the 'beforeinstallprompt' event listener to assist with an install prompt or button. If using a button, save the 'event' in a variable and use .prompt() on the variable to trigger the install.
      (e.g. 'pwaPrompt = event;
      ...(prompt user or display install button)
      ...(if user clicks install button)
      pwaPrompt.prompt() )`,
    },
    safariiOSUser: {
      code: `/(iPhone|iPod|iPad).*Mobile/.*Safari//i.test(
      navigator.userAgent
    )`,
      results: /(iPhone|iPod|iPad).*Mobile\/.*Safari\//i.test(
        navigator.userAgent
      ),
      info: ``,
    },
    userAgent: {
      code: `navigator.userAgent`,
      results: navigator.userAgent,
      info: `If available, userAgent can help determine the device type, OS and browser for customizing display and install buttons or prompts.
      (e.g. iOS and iPadOS do not only allow to add the pwa to the home screen through Safari. It cannot be added using another browser application and it does not support the prompt() to trigger the install prompts. A solution is to use a banner or tooltip to display only if the user is using Safari on an Apple mobile device or OS)`,
    },
    showInstall: { code: ``, results: true, info: `` },
    displayChange: {
      code: `window.matchMedia("(display-mode: standalone)")
    .addEventListener("change", (evt) => {(insert code)}`,
      results: ``,
      info: `An event listener can watch for a change from browser to standalone. Some browsers can install PWAs from the application instead of using a button, circumventing the beforeinstallprompt event.prompt(). Upon install, the browser may switch to automatically switch to the standalone mode.`,
    },
  });

  //   determine if the user starts app in standalone
  useEffect(() => {
    window
      .matchMedia("(display-mode: standalone)")
      .addEventListener("change", (evt) => {
        console.log("display mode change fired");
        if (evt.matches) {
          console.log("evt matches");
          setPwaPrompt({
            ...pwaPrompt,
            displayMode: {
              ...pwaPrompt.displayMode,
              results: window.matchMedia("(display-mode: standalone)").matches,
            },
            documentReferrer: {
              ...pwaPrompt.documentReferrer,
              results: document.referrer,
            },
            navigatorStandalone: {
              ...pwaPrompt.navigatorStandalone,
              results: navigator.standalone,
            },
            launchMode: {
              ...pwaPrompt.launchMode,
              results: "standalone",
            },
            installed: {
              ...pwaPrompt.installed,
              results: true,
            },
            showInstall: {
              ...pwaPrompt.showInstall,
              results: false,
            },
            safariiOSUser: {
              ...pwaPrompt.safariiOSUser,
              results:
                /^((?!chrome|android|Mac).)*Version\/[\d.]+.*Safari/i.test(
                  navigator.userAgent
                ),
            },
          });
        }
      });
  }, []);

  //   beforeinstallPrompt useEffect
  useEffect(() => {
    const getPWADisplayMode = () => {
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
      if (document.referrer.startsWith("android-app://")) {
        return "twa";
      } else if (navigator.standalone || isStandalone) {
        return "standalone";
      }

      return "browser";
    };

    if (pwaPrompt.showInstall || pwaPrompt.installed) {
      console.log("beforeinstallPrompt true");
      window.addEventListener("beforeinstallprompt", (event) => {
        console.log("beforeinstallPrompt fired");
        event.preventDefault();
        promptEvent = event;

        return setPwaPrompt({
          ...pwaPrompt,
          displayMode: {
            ...pwaPrompt.displayMode,
            results: window.matchMedia("(display-mode: standalone)").matches,
          },
          navigatorStandalone: {
            ...pwaPrompt.navigatorStandalone,
            results: navigator.standalone,
          },
          documentReferrer: {
            ...pwaPrompt.documentReferrer,
            results: document.referrer,
          },
          launchMode: { ...pwaPrompt.launchMode, results: getPWADisplayMode() },
          installed: { ...pwaPrompt.installed, results: false },
          showInstall: { ...pwaPrompt.showInstall, results: true },
          safariiOSUser: {
            ...pwaPrompt.safariiOSUser,
            results: /(iPhone|iPod|iPad).*Mobile\/.*Safari\//i.test(
              navigator.userAgent
            ),
          },
          userAgent: { ...pwaPrompt.userAgent, results: navigator.userAgent },
        });
      });
    }
  }, [pwaPrompt.showInstall, pwaPrompt.installed]);

  //   appinstalled eventListener
  window.addEventListener("appinstalled", () => {
    console.log("appinstalled fired");

    promptEvent = null;

    setPwaPrompt({
      ...pwaPrompt,
      installed: { ...pwaPrompt.installed, results: true },
      showInstall: { ...pwaPrompt.showInstall, results: false },
    });
  });

  const handleStyleChange = (event) => {
    event.preventDefault();
    console.log(event.target.idClassRadio.value);
    console.log(event.target.idClassName.value);

    if (event.target?.dataset?.type === "heightUnitType") {
      const scrollContainers = document.querySelectorAll(".scroll-container");

      scrollContainers.forEach((sc) => {
        sc.style.height = `100${event.target.innerText}`;
      });

      return;
    }

    if (!event.target.idClassRadio.value || !event.target.idClassName.value) {
      return;
    }

    let containerEl = [];
    if (event.target.idClassRadio.value === "idRadio") {
      containerEl = [document.getElementById(event.target.idClassName.value)];
    } else {
      containerEl = document.querySelectorAll(
        `.${event.target.idClassName.value}`
      );
    }

    if (!containerEl) {
      console.log("containerEl is false");
      return;
    } else {
      console.log(containerEl);
      containerEl.forEach((el) => {
        console.log(el);
        [
          "height",
          "paddingTop",
          "paddingBottom",
          "paddingRight",
          "paddingLeft",
          "boxShadow",
        ].forEach((style) => {
          console.log(style);
          console.log(event.target[style].value);

          if (!event.target[style] === "") {
            return;
          }

          if (style === "boxShadow") {
            console.log(
              `inset 0px 0px 0px ${event.target.boxShadow.value}px ${event.target.boxShadowColor.value}`
            );
            el.style.boxShadow = `inset 0px 0px 0px ${event.target.boxShadow.value}px ${event.target.boxShadowColor.value}`;
          } else {
            el.style[style] = event.target[style].value;
          }
        });

        // }
      });
    }
  };

  const PwaDataComponent = () => {
    return (
      <div
        className={`position-absolute border ${
          toggleStyle.showPdaBg && " bg-dark"
        }`}
        style={{
          top: "0px",
          left: "0px",
          marginLeft: "2%",
          width: "96%",
        }}
        hidden={!toggleStyle.showPWAData ? "hidden" : null}
      >
        <div>
          <Button
            name="showPdaBg"
            className="m-2"
            size="sm"
            variant="secondary"
            onClick={(event) => {
              return setToggleStyle({
                ...toggleStyle,
                [event.target.name]: !toggleStyle[event.target.name],
              });
            }}
          >
            {toggleStyle.showPdaBg ? "hide" : "show"}
            {` `}bg
          </Button>
        </div>
        <div>
          <code>
            <ul>
              {Object.entries(pwaPrompt).map((entry) => (
                <li key={entry[0]}>
                  {entry[0]}:{" "}
                  {entry[1].results === undefined
                    ? "undefined"
                    : entry[1].results === null
                    ? "null"
                    : entry[1].results.toString()}
                </li>
              ))}
            </ul>
          </code>
        </div>
      </div>
    );
  };

  const StyleFormComponent = () => {
    return (
      <div
        className={`position-absolute border-top pt-2`}
        style={{
          top: "0px",
          left: "0px",
          marginLeft: "2%",
          width: "96%",
        }}
        hidden={!toggleStyle.changeStyle ? "hidden" : null}
      >
        {/* view height row */}
        <Row className="mb-2">
          <Col className="pe-2" xs="auto">
            Height Unit Toggle:{" "}
          </Col>
          <Col className="ps-0 pe-2" xs="auto">
            <Button
              name="vh"
              data-type="heightUnitType"
              size="sm"
              variant="secondary"
              style={{
                width: "3rem",
              }}
              onClick={handleStyleChange}
            >
              vh
            </Button>
          </Col>
          <Col className="ps-0 pe-2" xs="auto">
            <Button
              name="svh"
              data-type="heightUnitType"
              size="sm"
              variant="secondary"
              style={{
                width: "3rem",
              }}
              onClick={handleStyleChange}
            >
              svh
            </Button>
          </Col>
          <Col className="ps-0 pe-2" xs="auto">
            <Button
              name="dvh"
              data-type="heightUnitType"
              size="sm"
              variant="secondary"
              style={{
                width: "3rem",
              }}
              onClick={handleStyleChange}
            >
              dvh
            </Button>
          </Col>
          <Col className="ps-0 pe-2" xs="auto">
            <Button
              name="lvh"
              data-type="heightUnitType"
              size="sm"
              variant="secondary"
              style={{
                width: "3rem",
              }}
              onClick={handleStyleChange}
            >
              lvh
            </Button>
          </Col>
        </Row>
        {/* heightNum row */}
        <div className="border-top pt-2">
          <Form className="" onSubmit={handleStyleChange} name="styleForm">
            <Row>
              <Col xs={{ span: 8, offset: 4 }} className="pe-0">
                <h6>Update id or class styles</h6>
              </Col>
            </Row>
            {/* id or class row */}
            <Form.Group as={Row} className="mb-2">
              <Col xs={{ span: 8, offset: 4 }} className="pe-0">
                <Form.Check
                  inline
                  label="id"
                  name="idClassRadio"
                  type="radio"
                  id={`idRadio`}
                  value="idRadio"
                />
                <Form.Check
                  inline
                  label="class"
                  name="idClassRadio"
                  type="radio"
                  id={`classRadio`}
                  value="classRadio"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col xs={4} className="pe-0 ps-1 text-end">
                <Form.Label>css id or class:</Form.Label>
              </Col>
              <Col xs={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="idClassName"
                  placeholder="scroll-container"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col xs={4} className="pe-0 ps-1 text-end">
                <Form.Label>height:</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  size="sm"
                  type="text"
                  name="height"
                  placeholder={"100vh"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col xs={4} className="pe-0 ps-1 text-end">
                <Form.Label>padding-top:</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  size="sm"
                  type="text"
                  name="paddingTop"
                  placeholder={"0%"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col xs={4} className="pe-0 ps-1 text-end">
                <Form.Label>padding-bottom:</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  size="sm"
                  type="text"
                  name="paddingBottom"
                  placeholder={"0%"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col xs={4} className="pe-0 ps-1 text-end">
                <Form.Label>padding-right:</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  size="sm"
                  type="text"
                  name="paddingRight"
                  placeholder={"0%"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col xs={4} className="pe-0 ps-1 text-end">
                <Form.Label>padding-left:</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  size="sm"
                  type="text"
                  name="paddingLeft"
                  placeholder={"0%"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col xs={4} className="pe-0 ps-1 text-end">
                <Form.Label>inset border:</Form.Label>
              </Col>
              <Col xs={5}>
                <InputGroup size="sm">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="boxShadow"
                    placeholder={"1"}
                  />
                  <InputGroup.Text>px</InputGroup.Text>
                  <Form.Control
                    size="sm"
                    type="color"
                    name="boxShadowColor"
                    defaultValue={"#AF3AFD"}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Col xs={{ span: 8, offset: 4 }} className="pe-0">
                <Button size="sm" type="submit" variant="secondary">
                  Submit Updates
                </Button>
              </Col>
            </Form.Group>
            <div className="border-top mt-4 mb-4"></div>
            <Form.Group as={Row} className="mb-2">
              <Col xs={4} className="pe-0 ps-1 text-end">
                <Form.Label>show border:</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Check size="sm" type="switch" name="padding-left" />
              </Col>
            </Form.Group>
          </Form>
        </div>
        {/* element outline row */}
        {/* <div className="mb-2">
          <span>Section Outline: </span>
          <Button
            name="boxShadow"
            data-type="boxShadow"
            size="sm"
            variant="secondary"
            onClick={handleStyleChange}
          >
            Add Outline
          </Button>
        </div> */}
      </div>
    );
  };

  const HeightGridsComponent = () => {
    return (
      <div
        className={`height-test-env ${toggleStyle.showHeightBg && " bg-dark"}`}
        style={{
          position: `absolute`,
          height: `100%`,
          width: `100%`,
          color: `white`,
        }}
        hidden={!toggleStyle.showHeights ? "hidden" : null}
      >
        {["vh", "lvh", "svh", "dvh", "%"].map((unit, index, unitArr) => (
          <div
            key={unit}
            className={`${
              toggleStyle.showHeightBg && " bg-secondary bg-opacity-25"
            }`}
            style={{
              position: `absolute`,
              top: `0px`,
              left: `${(100 / unitArr.length) * index}vw`,
              border: `1px dashed green`,
              height: `100vh`,
              width: `${100 / unitArr.length}vw`,
              paddingTop: `130%`,
              pointerEvents: `none`,
              fontSize: `.75rem`,
              textAlign: `center`,
              boxShadow: `inset 0px 0px 0px 2px #${Math.floor(
                Math.random() * 16777215
              ).toString(16)}`,
            }}
          >
            100{unit}
          </div>
        ))}

        <Button
          name="showHeightBg"
          className="m-2"
          style={{
            fontSize: `.75rem`,
            position: `absolute`,
            top: `4.5rem`,
            left: `2%`,
          }}
          size="sm"
          variant={toggleStyle.showHeightBg ? "dark" : "secondary"}
          onClick={(event) => {
            return setToggleStyle({
              ...toggleStyle,
              [event.target.name]: !toggleStyle[event.target.name],
            });
          }}
        >
          {toggleStyle.showHeightBg ? "hide" : "show"}
          {` `}bg
        </Button>
      </div>
    );
  };

  const EnvHeightComponent = () => {
    return (
      <div
        className={`height-test-env ${
          toggleStyle.showEnvBg && " bg-dark bg-opacity-25"
        }`}
        style={{
          position: `absolute`,
          top: `0px`,
          left: `0px`,
          border: `1px dashed orange`,
          height: `100vh`,
          width: `100vw`,
          boxShadow: `inset 0px 0px 2px 2px orange`,
        }}
        hidden={!toggleStyle.showEnvBox ? "hidden" : null}
      >
        <div
          className={`height-test-env ${
            toggleStyle.showEnvBg && " bg-secondary bg-opacity-50"
          }`}
          style={{
            height: `100%`,
            width: `100%`,
            boxShadow: `inset 0px 0px 2px 2px yellow`,
            textAlign: `center`,
            color: `white`,
            paddingTop: `50%`,
          }}
        >
          env box
          <br />
          <Button
            name="showEnvBg"
            className="m-1"
            size="sm"
            variant={toggleStyle.showEnvBg ? "dark" : "secondary"}
            onClick={(event) => {
              return setToggleStyle({
                ...toggleStyle,
                [event.target.name]: !toggleStyle[event.target.name],
              });
            }}
          >
            {toggleStyle.showEnvBg ? "hide" : "show"}
            {` `}bg
          </Button>
        </div>
      </div>
    );
  };

  //   console.log(formStyle);

  return (
    <section className="scroll-container test-page">
      {/* for testing */}
      <div
        className={`position-absolute top-0 start-0`}
        style={{
          zIndex: "2000",
          width: "100%",
        }}
      >
        {/* top buttons */}
        <div
          style={{
            paddingTop: `10%`,
            textAlign: `center`,
          }}
        >
          <Button
            name="showPWAData"
            className="m-1"
            size="sm"
            variant={toggleStyle.showPWAData ? "dark" : "secondary"}
            onClick={(event) => {
              return setToggleStyle({
                ...toggleStyle,
                [event.target.name]: !toggleStyle[event.target.name],
              });
            }}
          >
            {toggleStyle.showPWAData ? "Hide" : "Show"}
            {` `}PWA Data
          </Button>
          <Button
            name="showHeights"
            className="m-1"
            size="sm"
            variant={toggleStyle.showHeights ? "dark" : "secondary"}
            onClick={(event) => {
              return setToggleStyle({
                ...toggleStyle,
                [event.target.name]: !toggleStyle[event.target.name],
              });
            }}
          >
            {toggleStyle.showHeights ? "Hide" : "Show"}
            {` `}Heights
          </Button>
          <Button
            name="changeStyle"
            className="m-1"
            size="sm"
            variant={toggleStyle.changeStyle ? "dark" : "secondary"}
            onClick={(event) => {
              return setToggleStyle({
                ...toggleStyle,
                [event.target.name]: !toggleStyle[event.target.name],
              });
            }}
          >
            Change Heights
          </Button>
          <Button
            name="showEnvBox"
            className="m-1"
            size="sm"
            variant={toggleStyle.showEnvBox ? "dark" : "secondary"}
            onClick={(event) => {
              return setToggleStyle({
                ...toggleStyle,
                [event.target.name]: !toggleStyle[event.target.name],
              });
            }}
          >
            {toggleStyle.showEnvBox ? "Hide" : "Show"}
            {` `}EnvBox
          </Button>
        </div>
        <div className="position-relative">
          {/* showPDAData */}
          <PwaDataComponent />
          {/* changeStyle */}
          <StyleFormComponent />
        </div>
      </div>

      {/* env borders */}
      <EnvHeightComponent />

      {/* height borders */}
      <HeightGridsComponent />

      {/* end testing */}
      <div className="page-container" style={{ pointerEvents: `none` }}>
        <Row className="mb-5">
          <Col className="d-flex justify-content-center">
            <h1>Test Page{` ${page}`}</h1>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col className="d-flex justify-content-center">
            <div>
              <p className="handwritten-text fs-2">
                Save my app to your device.
              </p>
            </div>
          </Col>
        </Row>
        {pwaPrompt.safariiOSUser ? (
          <Row className="mb-3 position-absolute bottom-0 start-50 translate-middle-x">
            <Col className="d-flex justify-content-center">
              <Button
                id="installButton"
                name="installButton"
                variant="secondary"
                onClick={() => console.log("click")}
              >
                Install {` `}
                <Download />
              </Button>
            </Col>
          </Row>
        ) : null}
        {!pwaPrompt.safariiOSUser ? (
          <Row className="mb-3 position-absolute bottom-0 start-50 translate-middle-x">
            <Col className="d-flex justify-content-center">
              <Button
                id="installButton"
                name="installButton"
                variant="secondary"
                onClick={() => console.log("click")}
              >
                Install {` `}
                <Download />
              </Button>
            </Col>
          </Row>
        ) : null}
      </div>
    </section>
  );
};

export default TestPage;
