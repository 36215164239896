//react
import React, { useState, useEffect } from "react";

const Timer = ({ interval, timerToggle }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (!timerToggle) {
      setTime(0);
    } else {
      const intervalHandle = setInterval(() => {
        setTime((s) => {
          return s + 1;
        });
      }, interval);
    }
    // return clearInterval(intervalHandle);
  }, []);

  return <>{time}</>;
};

export default Timer;
