import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// omit other imports
import { useQuery } from "@apollo/client";
import { QUERY_ME } from "../../utils/queries";
import { loggedIn, loggedOut } from "./profileSlice";

export const Profile = () => {
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const { loading, data, error } = useQuery(QUERY_ME);

  useEffect(() => {
    if (loading) {
      console.log("loading");
      console.log(loading);
    }

    if (error) {
      console.log("error");
      console.log(error);
    }

    if (data) {
      console.log("data");
      console.log(data.me);
      dispatch(loggedIn(data.me));
    }
  }, [data, loading, error, dispatch]);

  function logoutClickHandler() {
    dispatch(loggedOut());
  }

  return (
    <>
      {loading ? (
        <div>loading...</div>
      ) : (
        <div>
          <div className="text-white">
            Profile Page...
            {profile.name}
          </div>
          <div className="text-white">
            <button aria-label="LoggedOut" onClick={() => logoutClickHandler()}>
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  );
};
